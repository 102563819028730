import styles from "./PortalWelcomeTile.module.css"
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { AuthContext } from "../../../../Context/Auth.Context";
import { AdminContext } from "../../../../Context/Admin.Context";
import { RoutingContext } from "../../../../Context/Routing.Context";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import Clock from "../../../../Shared/Components/UI/ThePageElements/Clock"


import TheButton from "../../../../Shared/Components/UI/TheButton/TheButton";
import NavigationLinkBox from "../../../../Shared/Components/UI/NavigationElements/NavigationLinkBox"
import NavigationLinks from "../../../../Shared/Components/UI/NavigationElements/NavigationLinks"
import {jwtDecode} from "jwt-decode";
import TheIcon from "../../../../Shared/Components/UI/TheIcon/TheIcon";
import { NavLink } from "react-router-dom";

const WelcomeTile = () => {
    const redirect = useNavigate();
    const { isHomeDepotApp } = useContext(RetailerContext);
    const { setIsRoutingState } = useContext(RoutingContext);
    const {
        isPortalActionState,
   

        setIsActiveSection,
    } = useContext(AdminContext);
 
    const {
        token,
        currentUser,
        setLoggedInUser,
        logout
    } = useContext(AuthContext);
    const decodedToken = token ? jwtDecode(token) : null;
    // const rawLoginDate =  isPortalActionState.portalUser.lastLoggedIn
    // const lastLoggedInDate = format(new Date(rawLoginDate), 'dd/MM/yyyy HH:mm:ss')

    const handleSectionClick = (sectionNumber) => {
        setIsActiveSection(sectionNumber);
        // console.log('section set to ', sectionNumber)
    }
    const handleLogoutClick = async (e) => {
        e.preventDefault();
        setLoggedInUser(null);
        logout(currentUser);
        setIsRoutingState(prevState => ({
            ...prevState,
            isNavLinkClicked: true,
            isAccountMenuOpen: false,
            isMobileAccountMenuOpen: false,
            isMobileNavOpen: false,
        }))
        // setIsNavLinkClicked(true)
        redirect(`${isHomeDepotApp.isHomeDepotPrefix}/sign-in`);
    }
    return (
        <div className={styles.welcomeTileContainer}>
            <div className={styles.item1}>
                <div className={styles.textBox}>
                    <p className={styles.subtitleText}>{isPortalActionState.portalUser.firstName}</p>
                </div>
                <div className={styles.textBox}>
                    <p className={styles.title}>Welcome to the LG Product Guide Portal!</p>
                </div>
                <div className={styles.clockWrapper}>
                    <p className={styles.linkText}>Today</p> <Clock />
                </div>

            </div>
            <div className={styles.item3}>
                <div className={styles.linkBoxWrapper}>

                    <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/user/${decodedToken?.userId}`}>
                        <div className={styles.linkIconBox}>
                            <TheIcon name="user" /><p className={styles.linkText}>Account</p>
                        </div>
                    </NavLink>
                    <NavLink to={`${isHomeDepotApp.isHomeDepotPrefix}/user-lists/${decodedToken?.userId}`}>
                        <div className={styles.linkIconBox}>
                            <TheIcon name="checkList" /><p className={styles.linkText}>Saved Lists</p>
                        </div>
                    </NavLink>

                    <div className={styles.linkIconBox}
                        onClick={handleLogoutClick}>
                        <TheIcon name="signout" /><p className={styles.linkText}>Logout</p>
                    </div>
                </div>
            </div>
            <div className={styles.item2}>
                <div className={styles.buttonRow}>


                    <div onClick={() => handleSectionClick(5)}>
                        <TheButton animated="true" styleName="black">
                        <div className={styles.buttonTextBox}>
                                <span className={styles.xButtonText}>View</span>
                                <span className={styles.buttonText}> Users</span>
                            </div>
                       
                        </TheButton>
                    </div>
                    <div onClick={() => handleSectionClick(2)}>
                        <TheButton animated="true" styleName="black">
                            <div className={styles.buttonTextBox}>
                                <span className={styles.xButtonText}>View</span>
                                <span className={styles.buttonText}>Products</span>
                            </div>
                        </TheButton>
                    </div>
                    <div onClick={() => handleSectionClick(10)}>
                        <TheButton animated="true" styleName="black">
                        <div className={styles.buttonTextBox}>
                                <span className={styles.xButtonText}>View</span>
                                <span className={styles.buttonText}>Analytics</span>
                            </div>
                          
                        </TheButton>
                    </div>
                </div>
            </div>
            {/* <div className={styles.item4}>
                <TheButton />
               
            </div> */}

            <div className={styles.textBox}></div>


        </div>

    )
}
export default WelcomeTile;