import TheIcon from "../TheIcon/TheIcon";


import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { SFWProductsContext } from "../../../../Context/CategoryContext";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";

import { LanguageContext } from "../../../../Context/Language.Context";
import SearchHeaderPreviewContainer from "../../../PurchasePro/Components/SearchHeader/SearchHeaderPreviewContainer";
import SearchHeaderTextContainer from "../../../PurchasePro/Components/SearchHeader/SearchHeaderTextContainer";
import { useContext, useState, useEffect } from "react";
import styles from "./SearchComponent.module.css";
import Overlay from "../../../../components/Overlay/Overlay"
import MobileSearchInput from "../../../PurchasePro/Components/SearchInput/MobileSearchInput";
import SearchInput from "../../../PurchasePro/Components/SearchInput/SearchInput";
import { SearchContext } from "../../../../Context/SearchContext";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import LGLogo from "../../../Theme/Branding/LGLogo";
import HomeDepotLogo from "../../../Theme/Branding/HomeDepotLogo";
const MobileSearchComponent = () => {

    const { isMobileSearchState, setIsMobileSearchState } = useContext(SearchContext);
    const { isHomeDepotApp } = useContext(RetailerContext)
    const { filteredProducts } = useContext(SFWProductsContext);
    

    const { builderListItems } = useContext(SFWBuilderContext);
    const [searchField, setSearchField] = useState('');
    const [builderProducts, setBuilderProducts] = useState([]);
    const [filteredBuilderProducts, setFilteredBuilderProducts] = useState(builderProducts);
    const [showSearchPreview, setShowSearchPreview] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);


    useEffect(() => {
        if (filteredProducts.length > 0) {
            setBuilderProducts(filteredProducts);
        }
    }, [filteredProducts]);


    useEffect(() => {
        if (searchField.length >= 2) {
            const newfilteredProducts = builderProducts.filter((product) => {
                // Exclude the product if it is already in builderListItems.
                if (builderListItems.some(item => item.title === product.title)) {
                    return false;
                }
                // Only include the product if its title matches the search field.
                return product.title && product.title.toLocaleLowerCase().includes(searchField);
            });
            setFilteredBuilderProducts(newfilteredProducts);
            setShowSearchPreview(true);
        } else {
            setFilteredBuilderProducts([]);
            setShowSearchPreview(false);
        }
    }, [builderProducts, searchField, builderListItems]);

    const onSearchChange = (event) => {
        const searchFieldString = event.target.value.toLocaleLowerCase();
        setSearchField(searchFieldString);
        setIsSearchActive(searchFieldString.length > 0);

    };
    const handleShowSearchComponent = () => {
        setIsMobileSearchState(prevState => ({
            ...prevState,
            isShowMobileSearchComponent: true
        }))
    }
    const handleCloseSearchComponent = () => {
        setIsMobileSearchState(prevState => ({
            ...prevState,
            isShowMobileSearchComponent: false
        }))
    }
  
    return (
        <div >
            <div onClick={handleShowSearchComponent}>
                <TheIcon name='search' />
            </div>
            {isMobileSearchState.isShowMobileSearchComponent &&
                <div className={styles.mobileSearchComponent}>
                    <Overlay>
                        <div className={styles.mobileSearchHeader}>
                            <div
                                onClick={handleCloseSearchComponent}
                                className={styles.closeSearchIcon} >
                                <TheIcon name='close' />
                            </div>
                            <div>
                                {isHomeDepotApp.isHomeDepotActive ? (
                                    <span onClick={handleCloseSearchComponent}>
                                        <HomeDepotLogo className={styles.logo} />
                                    </span>
                                )
                                    : (
                                        <span onClick={handleCloseSearchComponent}>
                                            <LGLogo className={styles.logo} />
                                        </span>
                                    )}

                                {/* <h2 className={styles.titleText}>{translatedText.searchPLHA}</h2> */}
                            </div>

                        </div>

                        <MobileSearchInput
                            id="search"
                            icon={isSearchActive ? undefined : faSearch}
                            onChange={onSearchChange}
                        />

                        <SearchHeaderPreviewContainer
                            show={showSearchPreview}
                            results={filteredBuilderProducts}
                        />
                    </Overlay>
                </div>
            }
        </div>
    );

}

const DesktopSearchComponent = () => {
   
    const { isHomeDepotApp } = useContext(RetailerContext)
    const { filteredProducts } = useContext(SFWProductsContext);

    const { builderListItems } = useContext(SFWBuilderContext);
    const [searchField, setSearchField] = useState('');
    const [builderProducts, setBuilderProducts] = useState([]);
    const [filteredBuilderProducts, setFilteredBuilderProducts] = useState(builderProducts);
    const [showSearchPreview, setShowSearchPreview] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const {isDesktopSearchState, setIsDesktopSearchState,isHomepageSearchState,isMobileSearchState}=useContext(SearchContext);

    useEffect(() => {
        if (filteredProducts.length > 0) {
            setBuilderProducts(filteredProducts);
        }
    }, [filteredProducts]);


    useEffect(() => {
        if (searchField.length >= 2) {
            const newfilteredProducts = builderProducts.filter((product) => {
                // Exclude the product if it is already in builderListItems.
                if (builderListItems.some(item => item.title === product.title)) {
                    return false;
                }
                // Only include the product if its title matches the search field.
                return product.title && product.title.toLocaleLowerCase().includes(searchField);
            });
            setFilteredBuilderProducts(newfilteredProducts);
            setShowSearchPreview(true);
            setIsDesktopSearchState(prevState=>({
                ...prevState,
                isSearchResults:newfilteredProducts,
            }))
        
        } else {
            setFilteredBuilderProducts([]);
            setShowSearchPreview(false);
            setIsDesktopSearchState(prevState=>({
                ...prevState,
                isSearchResults:[],
            }))
        }
    }, [builderProducts, searchField, builderListItems]);

    const onSearchChange = (event) => {
        const searchFieldString = event.target.value.toLocaleLowerCase();
        setSearchField(searchFieldString);
        setIsSearchActive(searchFieldString.length > 0);
       
    };
    // const handleShowSearchComponent = () => {
    //     setIsMobileSearchState(prevState => ({
    //         ...prevState,
    //         isShowMobileSearchComponent: true
    //     }))
    // }
    // const handleCloseSearchComponent = () => {
    //     setIsMobileSearchState(prevState => ({
    //         ...prevState,
    //         isShowMobileSearchComponent: false
    //     }))
    // }
    return (
        <div className={styles.desktopNavSearchComponent}>
            <SearchInput
                id="search"
                icon={isSearchActive ? undefined : faSearch}
                onChange={onSearchChange}
            />
            <div className={styles.desktopSearchPreviewWrapper}>
                <div>


                    <SearchHeaderPreviewContainer
                        show={showSearchPreview}
                        results={filteredBuilderProducts}
                    />
                </div>
            </div>
        </div>
    );

}

const ProductListSearchComponent = () => (
    <h1>Product List</h1>
)



const searchTypeMap = {
    mobile: MobileSearchComponent,
    desktop: DesktopSearchComponent,
    productListSearch: ProductListSearchComponent
}

export const SearchComponent = ({ type }) => {
    const RenderSearchComponent = searchTypeMap[type];
    return RenderSearchComponent ? <RenderSearchComponent /> : null;
}