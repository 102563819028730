import SearchPreview from "./SearchPreview"
import NoSearchResultsTextContainer from "./NoSearchResultsTextContainer"
import styles from "./SearchHeaderPreviewContainer.module.css"
import { useContext } from "react";
import { RoutingContext } from "../../../../Context/Routing.Context";

const SearchHeaderPreviewContainer = ({ show, results }) => {

    const {isRoutingState, setIsRoutingState}=useContext(RoutingContext)

    return (
        <div className={styles.searchHeaderPreviewWrapper}>
            {
                  show && 
                  (
                      results?.length > 0 ?
                          (<SearchPreview products={results} />)
                          : results?.length === 0 ?
                              (<NoSearchResultsTextContainer />)
                              : null
                  )
                // show && 
                // (
                //     results?.length > 0 ?
                //         (<SearchPreview products={results} />)
                //         : results?.length === 0 ?
                //             (<NoSearchResultsTextContainer />)
                //             : null
                // )
            }
        </div>
    );
};

export default SearchHeaderPreviewContainer