import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SFWProductsContext } from "../../../Context/CategoryContext";
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_URL, VALIDATOR_MAXLENGTH } from "../../../Utils/validators";
import { useForm } from "../../../Hooks/FormHook/form-hook";

import { useHttpClient } from "../../../Hooks/HttpHook/http-hook";
import styles from "./CreateProductPortalFormPage.module.css"


import Input from "../../../Shared/Components/UI/FormElements/Input/Input";
import ImageUpload from "../../../Shared/Components/UI/FormElements/ImageUpload/ImageUpload";
import CheckboxInput from "../../../Shared/Components/UI/FormElements/Checkbox/Checkbox";
import SelectInput from "../../../Shared/Components/UI/FormElements/Select/Select";
import { ResourceFormSection } from "../../PortalComponents/FormElements/Dynamic/ResourceFormSection";

import { jwtDecode } from "jwt-decode";
import PortalForm from "../../PortalComponents/FormElements/PortalForm";
import PortalFormRow from "../../PortalComponents/FormElements/PortalFormRow";

import PortalSectionTextBox from "../../PortalComponents/PortalUi/PortalSectionTextBox/PortalSectionTextBox";
import PortalFormImageUploadSection from "../../PortalComponents/FormElements/PortalFormImageUploadSection";

import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";



// DEFAULT FORM FIELDS
const defaultFormFields = {
    specList1EN: [],
    specList2EN: [],
    specList3EN: [],
    specList4EN: [],
    specList1FR: [],
    specList2FR: [],
    specList3FR: [],
    specList4FR: [],
};

const CreateProductPortalFormPage = () => {
    const redirect = useNavigate();
    const { setProducts } = useContext(SFWProductsContext);
    const { setIsRoutingState } = useContext(RoutingContext);
    const { setIsActiveSection } = useContext(AdminContext);
    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwtDecode(token) : null;

    const { sendRequest } = useHttpClient()


    //PRODUCT STATE
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [selectedLogos, setSelectedLogos] = useState([]);
    const [selectedColours, setSelectedColours] = useState([]);
    const [availability, setAvailability] = useState('');
    const [store, setStore] = useState('');
    const [sections, setSections] = useState([]);
    const [formField, setFormField] = useState(defaultFormFields);


    // const { values } = useDynamicForm({ sections: [] });

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);


    const handleChange = (sfw) => {
        const { name, value } = sfw.target;
        // Check if the name matches any of your dynamic fields
        const isDynamicTextarea = name.startsWith('specList') && (name.endsWith('EN') || name.endsWith('FR'));
        const processedValue = isDynamicTextarea
            ? value.split('\n').filter(item => item.trim() !== "")
            : value;
        setFormField(prevForm => ({ ...prevForm, [name]: processedValue }));

    }
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setSubcategory(""); // Reset the subcategory when category changes
    };
    const handleSubCategoryChange = (e) => {
        setSubcategory(e.target.value);
    };
    const handleAvailabilityChange = (event) => {
        setAvailability(event.target.value);
    }
    const handleStoreChange = (event) => {
        setStore(event.target.value);
    };
    //
    //LOGO HANDLER
    const handleLogoChange = (value, isChecked) => {
        if (isChecked) {
            setSelectedLogos([...selectedLogos, value]);
        } else {
            setSelectedLogos(selectedLogos.filter(name => name !== value));
        }
    };

    const handleColourChange = (value, isChecked) => {
        let newSelectedColours;
        if (isChecked) {
            newSelectedColours = [...selectedColours, value];
        } else {
            newSelectedColours = selectedColours.filter(name => name !== value);
        }
        setSelectedColours(newSelectedColours);
    };

    const [formState, inputHandler] = useForm({
        title: {
            value: '',
            isValid: false
        },
        msrp: {
            value: '',
            isValid: false
        },
        // IMAGES & QR CODE
        image: {
            value: null,
            isValid: false
        },
        // qrCodeSpecSheetEN: {
        //     value: null,
        //     isValid: true
        // },
        // qrCodeSpecSheetFR: {
        //     value: null,
        //     isValid: true
        // },
        subtitleEN: {
            value: '',
            isValid: false
        },
        subtitleFR: {
            value: '',
            isValid: false
        },
        upcCodeEN: {
            value: '',
            isValid: false
        },
        upcCodeFR: {
            value: '',
            isValid: false
        },
        // installGuideLinkEN: {
        //     value: '',
        //     isValid: true
        // },
        // installGuideLinkFR: {
        //     value: '',
        //     isValid: true
        // },

        // ownerManualLinkEN: {
        //     value: '',
        //     isValid: true
        // },
        // ownerManualLinkFR: {
        //     value: '',
        //     isValid: true
        // },
        // specSheetLinkEN: {
        //     value: '',
        //     isValid: true
        // },
        // specSheetLinkFR: {
        //     value: '',
        //     isValid: true
        // },

        videosEN: {
            value: '',
            isValid: true
        },
        videosFR: {
            value: '',
            isValid: true
        },

    }, false);

    //DATE - AVAILABILITY FUNCTION
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    const subcategoryOptions = {

        "LG Studio": [ //PHASE 2 UPDATED
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Built-in Cooking", label: "Built-in Cooking" }, //NEW
            { value: "Free-standing Cooking", label: "Free-standing Cooking" }, //NEW
            { value: "Ventilation", label: "Ventilation" },  //NEW
            { value: "Refrigeration", label: "Refrigeration" },
            { value: "Dishwashers", label: "Dishwashers" },
            { value: "Laundry", label: "Laundry" },
        ],
        "Laundry": [  //PHASE 2 NO CHANGE
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Front Load", label: "Front Load" },
            { value: "WashTower", label: "WashTower" },
            { value: "Top Load", label: "Top Load" },
            { value: "Specialty Laundry", label: "Specialty Laundry" },
            { value: "Accessories", label: "Accessories" }

        ],
        "Refrigeration": [ //PHASE 2 UPDATED
            { value: "", label: "Select Subcategory", disabled: true },
            { value: `36" French Door Counter Depth`, label: `36" French Door Counter Depth` }, //NEW
            { value: `36" French Door Standard Depth`, label: `36" French Door Standard Depth` }, //NEW
            { value: `33" French Door Counter Depth`, label: `33" French Door Counter Depth` }, //NEW
            { value: `33" French Door Standard Depth`, label: `33" French Door Standard Depth` }, //NEW
            { value: `30" French Door`, label: `30" French Door` }, //NEW
            { value: "Side-by-Side", label: "Side-by-Side" },
            // { value: "Top Mount", label: "Top Mount" },
            { value: "Top and Bottom Mount", label: "Top and Bottom Mount" },
            { value: "Column", label: "Column" },
            { value: "Kimchi", label: "Kimchi" },
        ],

        "Cooking": [//PHASE 2 UPDATED
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Electric Slide-in Ranges", label: "Electric Slide-in Ranges" },  //NEW
            { value: "Electric Free-standing Ranges", label: "Electric Free-standing Ranges" },  //NEW
            { value: "Induction Ranges", label: "Induction Ranges" },  //NEW
            { value: "Gas Ranges", label: "Gas Ranges" },
            { value: "Wall Ovens", label: "Wall Ovens" }, //NEW NAME 
            { value: "Cooktops", label: "Cooktops" }, //NEW NAME 
            { value: "Over-the-Range Microwaves", label: "Over-the-Range Microwaves" },
            { value: "Countertop Microwaves", label: "Countertop Microwaves" },
        ],
        "Dishwasher": [ //PHASE 2 NAME CHANGE FOR SUB
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Built-in", label: "Built-in" },
        ]

    };

    const coloumTitleCategory = {
        "Laundry": {// PHASE 2 NO CHANGE
            'Front Load': [
                { value: "Specifications", label: "Specifications" },

                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
                { value: "Matching Add-ons", label: "Matching Add-ons" },
            ],
            'Top Load': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
                { value: "Matching Add-ons", label: "Matching Add-ons" },
            ],
            'Specialty Laundry': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
                { value: "Matching Add-ons", label: "Matching Add-ons" },
            ],
            'WashTower': [
                { value: "Specifications", label: "Specifications" },
                { value: "Innovations", label: "Innovations" },
            ],
            // NEW
            'Accessories': [
                { value: "Specifications", label: "Specifications" },
            ],
        },
        "Refrigeration": {  // PHASE 2 UPDATED

            "36\" French Door Counter Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            "36\" French Door Standard Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            "33\" French Door Counter Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            "33\" French Door Standard Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            "30\" French Door": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            'Side-by-Side': [
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            'Top and Bottom Mount': [ //COMBINE WITH BOTTOM MOUNT
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            'Kimchi': [
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            'Column': [
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
                { value: "Optional Panels", label: "Optional Panels" },
            ],
        },

        "Cooking": { // PHASE 2 UPDATED
            'Electric Slide-in Ranges': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Electric Free-standing Ranges': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Induction Ranges': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Gas Ranges': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Over-the-Range Microwaves': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],

            'Wall Ovens': [ //NEW NAME
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
            ],

            'Cooktops': [ //NEW NAME
                { value: "Specifications", label: "Specifications" },
            ],
            'Countertop Microwaves': [
                { value: "Specifications", label: "Specifications" },
                { value: "Optional Trim Kit", label: "Optional Trim Kit" },
            ],
        },
        "Dishwasher": { // NAME CHANGE REQUESTED
            // 'Dishwasher': [
            'Built-in': [
                { value: "Specifications", label: "Specifications" },
                { value: "Innovations", label: "Innovations" },
                { value: "Stylish Design", label: "Stylish Design" },
            ],
        },

        "LG Studio": { // PHASE 2 UPDATED
            'Built-in Cooking': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Free-standing Cooking': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Ventilation': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Refrigeration': [
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Dishwashers': [
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Laundry': [
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
        }

    };

    // console.log('fs', formState)
    const productSubmitHandler = async (event) => {


        // setIsLoading(true)
        event.preventDefault();
        setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: true
        }))

        // console.log(formField.specList1EN)
        let errorMessage = [];
        if (!formState.inputs.title.value) errorMessage.push('Title missing or invalid.');
        if (!formState.inputs.msrp.value) errorMessage.push('Price missing or invalid.');
        if (formField.specList1EN.length === 0 || formField.specList1FR.length === 0) errorMessage.push('Specs missing or invalid.');
        if (!category) errorMessage.push('Category missing or invalid.');
        // if (!selectedColours) errorMessage.push('Colour missing or invalid.');
        if (selectedColours.length === 0) errorMessage.push('Colour selection missing');
        // if (selectedLogos.length === 0) errorMessage.push('Logos missing or invalid.');
        if (!subcategory) errorMessage.push('Subcategory selection missing');
        if (!store) errorMessage.push('Retailer selection missing.');
        if (!availability) errorMessage.push('Availability selection missing.');
        if (!formState.inputs.subtitleEN.value || !formState.inputs.subtitleFR.value) errorMessage.push('Subtitle(s) missing or invalid.')
        if (!formState.inputs.upcCodeEN.value || !formState.inputs.upcCodeFR.value) errorMessage.push('UPC code(s) missing or invalid.')
        if (!formState.inputs.image.value) errorMessage.push('Image missing or invalid.')

        if (errorMessage.length > 0) {
            // console.log('r3')
            // setIsLoading(false)
            // setShowModal(true)
            // setError({
            //     title: 'Please fix errors.',
            //     errorList: errorMessage
            // });
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Product creation failed',
                message: 'Please fix the errors below.',
                errorList: errorMessage,
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });

            return;
        }

        const formData = new FormData();
        formData.append('title', formState.inputs.title.value,)
        formData.append('msrp', formState.inputs.msrp.value,)
        formData.append('store', store)

        formData.append('availability', availability)
        formData.append('category', category)
        formData.append('subcategory', subcategory)

        // Append specList1EN items
        formField.specList1EN.forEach(item => {
            formData.append('specList1EN[]', item);
        });

        // Append specList2EN items
        formField.specList2EN.forEach(item => {
            formData.append('specList2EN[]', item);
        });

        // Append specList3EN items
        formField.specList3EN.forEach(item => {
            formData.append('specList3EN[]', item);
        });

        // Append specList4EN items
        formField.specList4EN.forEach(item => {
            formData.append('specList4EN[]', item);
        });

        // Append specList1FR items
        formField.specList1FR.forEach(item => {
            formData.append('specList1FR[]', item);
        });

        // Append specList2FR items
        formField.specList2FR.forEach(item => {
            formData.append('specList2FR[]', item);
        });

        // Append specList3FR items
        formField.specList3FR.forEach(item => {
            formData.append('specList3FR[]', item);
        });

        // Append specList4FR items
        formField.specList4FR.forEach(item => {
            formData.append('specList4FR[]', item);
        });


        formData.append('subtitleEN', formState.inputs.subtitleEN.value,)
        formData.append('subtitleFR', formState.inputs.subtitleFR.value,)
        // formData.append('upcCodeEN', formState.inputs.upcCodeEN.value,);
        // formData.append('upcCodeFR', formState.inputs.upcCodeFR.value,);
        if (formState.inputs.upcCodeEN.value.includes('\n')) {
            formState.inputs.upcCodeEN.value.split('\n').forEach(item => {
                formData.append('upcCodeEN', item.trim());
            });
        } else {
            formData.append('upcCodeEN', formState.inputs.upcCodeEN.value.trim());
        }
        if (formState.inputs.upcCodeFR.value.includes('\n')) {
            formState.inputs.upcCodeFR.value.split('\n').forEach(item => {
                formData.append('upcCodeFR', item.trim());
            });
        } else {
            formData.append('upcCodeFR', formState.inputs.upcCodeFR.value.trim());
        }

        // formData.append('installGuideLinkEN', formState.inputs.installGuideLinkEN.value,);
        // formData.append('installGuideLinkFR', formState.inputs.installGuideLinkFR.value,);

        // formData.append('specSheetLinkEN', formState.inputs.specSheetLinkEN.value);
        // formData.append('specSheetLinkFR', formState.inputs.specSheetLinkFR.value);

        // formData.append('ownerManualLinkEN', formState.inputs.ownerManualLinkEN.value);
        // formData.append('ownerManualLinkFR', formState.inputs.ownerManualLinkFR.value);


        if (formState.inputs.videosEN.value.includes(' ')) {  // Check if the value contains a space indicating multiple URLs
            formState.inputs.videosEN.value.split(' ').forEach(item => {
                formData.append('videosEN', item.trim());
            });
        } else {
            formData.append('videosEN', formState.inputs.videosEN.value.trim());
        }

        if (formState.inputs.videosFR.value.includes(' ')) {
            formState.inputs.videosFR.value.split(' ').forEach(item => {
                formData.append('videosFR', item.trim());
            });
        } else {
            formData.append('videosFR', formState.inputs.videosFR.value.trim());
        }
        formData.append('image', formState.inputs.image.value);
        // formData.append('qrCodeSpecSheetEN', formState.inputs.qrCodeSpecSheetEN.value);
        // formData.append('qrCodeSpecSheetFR', formState.inputs.qrCodeSpecSheetFR.value);


        formData.append('creator', decodedToken.userId);

        for (const logo of selectedLogos) {
            formData.append('logos', logo);
        }
        for (const colour of selectedColours) {
            formData.append('colour', colour);
        }

        sections.forEach((section, index) => {
            formData.append(`sections[${index}][resourceTitleEN]`, section.resourceTitleEN);
            formData.append(`sections[${index}][resourceUrlEN]`, section.resourceUrlEN);

            if (section.resourceQrCodeImageEN instanceof File) {
                // New file selected
                formData.append(`sections[${index}][resourceQrCodeImageEN]`, section.resourceQrCodeImageEN);
            } else if (section.resourceQrCodeImageEN) {
                // Original image path should be preserved
                formData.append(`sections[${index}][resourceQrCodeImageEN]`, section.originalResourceQrCodeImageEN);
            }

            formData.append(`sections[${index}][resourceTitleFR]`, section.resourceTitleFR);
            formData.append(`sections[${index}][resourceUrlFR]`, section.resourceUrlFR);

            if (section.resourceQrCodeImageFR instanceof File) {
                // New file selected
                formData.append(`sections[${index}][resourceQrCodeImageFR]`, section.resourceQrCodeImageFR);
            } else if (section.resourceQrCodeImageFR) {
                // Original image path should be preserved
                formData.append(`sections[${index}][resourceQrCodeImageFR]`, section.originalResourceQrCodeImageFR);
            }
        });

        try {
            // const response = await sendRequest(`http://localhost:3000/add-product`,
            const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/add-product`,
                'POST',
                formData,
                { 'Authorization': `Bearer ${token}` }
            );

            // If the status code indicates success (200-299), handle the successful case
            if (response.message === 'The product has been created! - server') {
                // Update the products context by appending the new product
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false
                }))
                setProducts(prevProducts => [
                    ...prevProducts,
                    response.product
                ]);
                setModalInfo({
                    show: true,
                    title: 'Product created',
                    message: 'The product has been created successfully!',
                    errorList: [],
                    confirmText: 'Close',
                    wasSuccessful: true,
                    onConfirm: productCreationSuccessHanlder,
                    onClose: productCreationSuccessHanlder
                });

            }
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))


        } catch (err) {
            console.log('the error', err)
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The product could not be created.' + err.message,
                errorList: [],
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
        }
    };

    const productCreationSuccessHanlder = () => {
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(2)
        redirect('/admin')
    }


    return (
        <>
            <Modal
                icon
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />


            <PortalForm
                title="Create Product"
                subtitle="Admin Portal"
                headline="Complete the form below to create and add products to the website."
                onSubmit={productSubmitHandler}
            >
                <PortalFormRow>
                    <Input
                        id="title"
                        type="text"
                        element="input"
                        label="Title"
                        validators={[VALIDATOR_MINLENGTH(5)]}
                        errorText="5 character minimum."
                        onInput={inputHandler}

                    />
                    <Input
                        id="msrp"
                        type="number"
                        element="input"
                        label="MSRP"
                        validators={[VALIDATOR_MINLENGTH(1), VALIDATOR_MAXLENGTH(10)]}
                        errorText="MSRP required. 10 digit max."
                        onInput={inputHandler}

                    />
                </PortalFormRow>
                <PortalFormRow>
                    <Input
                        id="subtitleEN"
                        type="text"
                        element="input"
                        label="Subtitle (English)"
                        validators={[VALIDATOR_MINLENGTH(8), VALIDATOR_MAXLENGTH(300)]}
                        // errorText="Your product subtitle is too short. Please provide a subtitle that is at least 5 characters long."
                        errorText="Must be between 8-300 characters."
                        onInput={inputHandler}

                    />
                    <Input
                        id="subtitleFR"
                        type="text"
                        element="input"
                        label="Subtitle (French)"
                        validators={[VALIDATOR_MINLENGTH(5)]}
                        errorText="5 character minimum."
                        onInput={inputHandler}
                    />
                </PortalFormRow>
                <PortalSectionTextBox
                    title="Category Selection"
                    headline="Once a category is selected the sub category options will appear."
                />
                {/* <div className={styles.sectionBlockTitleText}>
                        <p className={styles.sectionLabelText}>Category Selection </p>
                        <p className={styles.noteText} >Once a category is selected the sub category options will appear.</p>
                    </div> */}
                <PortalFormRow>
                    <SelectInput
                        id="category"
                        name="category"
                        value={category}
                        validators={[VALIDATOR_REQUIRE()]}
                        // errorText="Category required."
                        labelName="Category"
                        onChange={handleCategoryChange}
                        options={[
                            { value: "", label: "Select Category", disabled: true },
                            { value: "LG Studio", label: "LG Studio" },
                            { value: "Laundry", label: "Laundry" },
                            { value: "Refrigeration", label: "Refrigeration" },
                            { value: "Cooking", label: "Cooking" },
                            { value: "Dishwasher", label: "Dishwasher" },
                        ]}

                    />
                    {category && (

                        <SelectInput
                            id="subcategory"
                            name="subcategory"
                            value={subcategory}

                            validators={[VALIDATOR_REQUIRE()]}
                            onChange={handleSubCategoryChange}
                            options={subcategoryOptions[category]}
                            labelName="Subcategory"
                        />
                    )}
                </PortalFormRow>
                {
                    category && subcategory &&
                    <PortalSectionTextBox
                        title="Technical Specifications"
                        headline="The title for each column is based on the selected category / subcategory. To populate a product's 'Related Products' carousel, use special keyword(s) 'optional', 'matching', and/or 'accessories', and wrap the target product(s) in brackets. Insert brackets around the title of the accessory you want to feature as a related product. ie. Matching washer (WM6998H_A), Optional (WM9500HKA,WD205CK, LUWM101HWA), Accessories (SWWE50N3 SWWG50N3, DLEX9500K / DLGX9501K, DLEX4000_ DLGX4001_) Minimum completion of 1 column in English and French."
                    />
                }
                {/* <PortalFormRow> */}
                {

                    category && subcategory && coloumTitleCategory[category]
                        && coloumTitleCategory[category][subcategory] ?
                        coloumTitleCategory[category][subcategory].map((item, index) => {
                            index++; // increment index here
                            return (
                                <>
                                    <div key={item.value}>
                                        <PortalFormRow>
                                            <div className={styles.specsTextAreaWrapper}>
                                                <div className={styles.labelTextWrapper}>
                                                    <p className={styles.labelText}>
                                                        {item.label} {`(English)`}
                                                    </p>
                                                    <p className={styles.labelInfoText}>
                                                        Add one per line. Hit enter to get to the next line to add another.
                                                    </p>
                                                </div>
                                                <textarea
                                                    type="text"
                                                    name={`specList${index}EN`}
                                                    onChange={handleChange}
                                                    rows={20}
                                                    placeholder="Add English"
                                                />
                                            </div>
                                            <div className={styles.specsTextAreaWrapper}>
                                                <div className={styles.labelTextWrapper}>
                                                    <p className={styles.labelText}>
                                                        {item.label} {`(French)`}
                                                    </p>
                                                    <p className={styles.labelInfoText}>
                                                        Add one per line. Hit enter to get to the next line to add another.
                                                    </p>
                                                </div>
                                                <textarea
                                                    type="text"
                                                    name={`specList${index}FR`}
                                                    onChange={handleChange}
                                                    rows={20}
                                                    placeholder="Add French"
                                                />
                                            </div>
                                        </PortalFormRow>
                                    </div>
                                </>
                            )
                        }) : null
                }
                {/* </PortalFormRow> */}
                <PortalSectionTextBox
                    title="UPC Codes (English | French)"
                    headline="Add one per line. Hit enter to get to the next line to add another."
                />
                <PortalFormRow>
                    <Input
                        // req
                        id="upcCodeEN"
                        type="text"
                        // element="input"
                        element="textarea"
                        rows={10}
                        label="UPC Code (English)"
                        validators={[VALIDATOR_MINLENGTH(5)]}
                        // errorText="Your product UPC code is too short. Please provide a UPC code that is at least 5 characters long."
                        errorText="5 character minimum."
                        onInput={inputHandler}
                        placeholder="Add English"
                    />
                    <Input
                        id="upcCodeFR"
                        type="text"
                        // element="input"
                        element="textarea"
                        rows={10}
                        label="UPC Code (French)"
                        validators={[VALIDATOR_MINLENGTH(5)]}
                        // errorText="Your product UPC code is too short. Please provide a UPC code that is at least 5 characters long."
                        errorText="5 character minimum."
                        onInput={inputHandler}
                        placeholder="Add French"

                    />
                </PortalFormRow>

                <PortalFormRow>
                    <SelectInput

                        id="store"
                        name="store"
                        value={store}
                        validators={[VALIDATOR_REQUIRE()]}
                        labelName="Retailer"
                        onChange={handleStoreChange}
                        options={[
                            { value: "", label: "Select Store", disabled: true },
                            { value: "LG", label: "LG Generic" },
                            { value: "HD", label: "Home Depot Exclusive" }
                        ]}
                    />
                    <SelectInput

                        id="availability"
                        name="availability"
                        value={availability}
                        labelName="Availability"
                        onChange={handleAvailabilityChange}
                        options={[
                            { value: "", label: "Set Availability", disabled: true },
                            { value: `Available`, label: `Available` },
                            { value: `EOL`, label: `EOL` },
                            { value: `Q1 ${currentYear}`, label: `Q1 ${currentYear}` },
                            { value: `Q2 ${currentYear}`, label: `Q2 ${currentYear}` },
                            { value: `Q3 ${currentYear}`, label: `Q3 ${currentYear}` },
                            { value: `Q4 ${currentYear}`, label: `Q4 ${currentYear}` },
                            { value: `Q1 ${nextYear}`, label: `Q1 ${nextYear}` },
                            { value: `Q2 ${nextYear}`, label: `Q2 ${nextYear}` },
                            { value: `Q3 ${nextYear}`, label: `Q3 ${nextYear}` },
                            { value: `Q4 ${nextYear}`, label: `Q4 ${nextYear}` },
                        ]}
                    />
                </PortalFormRow>


                {/* <PortalSectionTextBox
                    title="URL links to external website"
                    headline="Please inspect the product page once the product is added to ensure links are working properly."
                />
                <PortalFormRow>
                    <Input
                        id="installGuideLinkEN"
                        type="text"
                        element="input"
                        label="Installation Guide URL (English)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}
                    />
                    <Input
                        id="installGuideLinkFR"
                        type="text"
                        element="input"
                        label="Installation Guide URL (French)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}
                    />
                </PortalFormRow>
                <PortalFormRow>
                    <Input
                        id="specSheetLinkEN"
                        type="text"
                        element="input"
                        label="Spec Sheet Link URL (English)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}
                    />
                    <Input
                        id="specSheetLinkFR"
                        type="text"
                        element="input"
                        label="Spec Sheet Link URL (French)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}
                    />
                </PortalFormRow>
                <PortalFormRow>
                    <Input
                        id="ownerManualLinkEN"
                        type="text"
                        element="input"
                        label="Owners Manual Link URL (English)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}
                    />
                    <Input
                        id="ownerManualLinkFR"
                        type="text"
                        element="input"
                        label="Owners Manual Link URL (French)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}
                    />
                </PortalFormRow> */}


                <PortalSectionTextBox
                    title="Feature Videos"
                    headline="Embeded URL required. Example. www.youtube.com/embed/NjNveGgThEo, https://player.vimeo.com/video/978422792"
                />

                <PortalFormRow>
                    <Input
                        id="videosEN"
                        type="text"
                        element="textarea"
                        rows={3}
                        label="Feature Video URLs (English)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}

                    />
                    <Input
                        id="videosFR"
                        type="text"
                        element="textarea"
                        rows={3}
                        label="Feature Video URLs (French)"
                        validators={[VALIDATOR_URL()]}
                        errorText="Please enter a valid URL"
                        onInput={inputHandler}
                    />
                </PortalFormRow>
                <PortalSectionTextBox
                    title="Model Colours"
                    headline="Minimum 1 selection"
                />

                <PortalFormRow>


                    {/* NEW */}
                    <CheckboxInput
                        label="Platinum Silver"
                        value="Platinum Silver"
                        id="platinum-silver"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Platinum Silver")} />
                    <CheckboxInput
                        label="Glass Beige"
                        value="Glass Beige"
                        id="glass-beige"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Glass Beige")} />

                    <CheckboxInput
                        label="Nature Green"
                        value="Nature Green"
                        id="nature-green"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Nature Green")} />

                    <CheckboxInput
                        label="Essence White"
                        value="Essence White"
                        id="essence-white"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Essence White")} />

                    <CheckboxInput
                        label="Candy Apple Red"
                        value="Candy Apple Red"
                        id="candy-apple-red"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Candy Apple Red")} />
                    <CheckboxInput
                        label="Noble Steel"
                        value="Noble Steel"
                        id="noble-steel"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Noble Steel")} />
                    <CheckboxInput
                        label="Monochrome grey"
                        value="Monochrome grey"
                        id="monochrome-grey"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Monochrome grey")} />

                    <CheckboxInput
                        label="Mirror"
                        value="Mirror"
                        id="Mirror"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Mirror")} />

                    {/* */}
                    <CheckboxInput
                        label="White"
                        value="White"
                        id="white"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("White")} />
                    <CheckboxInput
                        label="Smooth White"
                        value="Smooth White"
                        id="smooth-white"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Smooth White")}
                    />
                    <CheckboxInput
                        label="Smooth Black"
                        value="Smooth Black"
                        id="smooth-black"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Smooth Black")}
                    />
                    <CheckboxInput
                        label="Black Steel"
                        value="Black Steel"
                        id="black-steel"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Black Steel")}
                    />
                    <CheckboxInput
                        label="Graphite Steel"
                        value="Graphite Steel"
                        id="graphite-steel"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Graphite Steel")}
                    />
                    <CheckboxInput
                        label="Stainless Steel"
                        value="Stainless Steel"
                        id="stainless-steel"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Stainless Steel")}
                    />
                    <CheckboxInput
                        label="Black Stainless Steel"
                        value="Black Stainless Steel"
                        id="black-stainless-steel"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Black Stainless Steel")}
                    />
                    <CheckboxInput
                        label="Smudge Resistant Stainless Steel"
                        value="Smudge Resistant Stainless Steel"
                        id="smudge-resistant-stainless-steel"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Smudge Resistant Stainless Steel")}
                    />
                    <CheckboxInput
                        label="Smudge Resistant Matte Black"
                        value="Smudge Resistant Matte Black"
                        id="smudge-resistant-matte-black"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Smudge Resistant Matte Black")}
                    />
                    <CheckboxInput
                        label="Smudge Resistant Black Stainless Steel"
                        value="Smudge Resistant Black Stainless Steel"
                        id="smudge-resistant-black-stainless-steel"
                        type="checkbox"
                        onChange={handleColourChange}
                        checked={selectedColours.includes("Smudge Resistant Black Stainless Steel")}
                    />
                </PortalFormRow>
                <PortalSectionTextBox
                    title="Technology Logos"
                    headline="Optional"
                />

                <PortalFormRow>
                    {/* NEW */}
                    <CheckboxInput
                        label="Zero Clearance"
                        value="zeroClearance"
                        id="zeroClearance"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("zeroClearance")}
                    />
                    <CheckboxInput
                        label="1 Hour Complete Wash Dry"
                        value="1HrCompleteWashDry"
                        id="1HrCompleteWashDry"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("1HrCompleteWashDry")}
                    />
                    <CheckboxInput
                        label="2 Hour Wash Dry"
                        value="2HrWashDry"
                        id="2HrWashDry"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("2HrWashDry")}
                    />
                    <CheckboxInput
                        label="4 Way Agitator"
                        value="4WayAgitator"
                        id="4WayAgitator"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("4WayAgitator")}
                    />
                    <CheckboxInput
                        label="6 Motion Direct Drive"
                        value="6MotionDirectDrive"
                        id="6MotionDirectDrive"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("6MotionDirectDrive")}
                    />
                    <CheckboxInput
                        label="Refrigeration Standard Max"
                        value="RefStandardMax"
                        id="RefStandardMax"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("RefStandardMax")}
                    />
                    <CheckboxInput
                        label="InstaView My Color"
                        value="InstaViewMyColor"
                        id="InstaViewMyColor"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("InstaViewMyColor")}
                    />

                    <CheckboxInput
                        label="Canada Reliability Badge"
                        value="CanadaReliabilityBadge"
                        id="CanadaReliabilityBadge"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("CanadaReliabilityBadge")}
                    />
                    <CheckboxInput
                        label="Certified Asthma"
                        value="CertifiedAsthma"
                        id="CertifiedAsthma"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("CertifiedAsthma")}
                    />
                    <CheckboxInput
                        label="Easy Clean"
                        value="EasyClean"
                        id="EasyClean"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("EasyClean")}
                    />
                    <CheckboxInput
                        label="LG Dynamic HeatDry"
                        value="LGDynamicHeatDry"
                        id="LGDynamicHeatDry"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("LGDynamicHeatDry")}
                    />
                    <CheckboxInput
                        label="ProBake Convection"
                        value="ProBakeConvection"
                        id="ProBakeConvection"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("ProBakeConvection")}
                    />
                    <CheckboxInput
                        label="QuadWash"
                        value="QuadWash"
                        id="QuadWash"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("QuadWash")}
                    />
                    <CheckboxInput
                        label="Slim Space Plus"
                        value="SlimSpacePlus"
                        id="SlimSpacePlus"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("SlimSpacePlus")}
                    />
                    <CheckboxInput
                        label="Smart Diagnosis"
                        value="SmartDiagnosis"
                        id="SmartDiagnosis"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("SmartDiagnosis")}
                    />
                    <CheckboxInput
                        label="Smart Inverter Compressor 10 Year Warranty"
                        value="SmartInverterCompressor10YearWarranty"
                        id="SmartInverterCompressor10YearWarranty"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("SmartInverterCompressor10YearWarranty")}
                    />
                    <CheckboxInput
                        label="TrueBalance"
                        value="TrueBalance"
                        id="TrueBalance"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("TrueBalance")}
                    />
                    <CheckboxInput
                        label="TrueSteam"
                        value="TrueSteam"
                        id="TrueSteam"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("TrueSteam")}
                    />
                    <CheckboxInput
                        label="TurboSteam"
                        value="TurboSteam"
                        id="TurboSteam"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("TurboSteam")}
                    />
                    <CheckboxInput
                        label="TurboWash3D"
                        value="TurboWash3D"
                        id="TurboWash3D"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("TurboWash3D")}
                    />
                    <CheckboxInput
                        label="WashCombo"
                        value="WashCombo"
                        id="WashCombo"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("WashCombo")}
                    />
                    {/*  */}
                    <CheckboxInput
                        label="Google"
                        value="Google"
                        id="google"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("Google")}
                    />
                    <CheckboxInput
                        label="Amazon"
                        value="Amazon"
                        id="amazon" type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("Amazon")}
                    />
                    <CheckboxInput
                        label="Asthma"
                        value="Asthma"
                        id="asthma"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("Asthma")}
                    />

                    <CheckboxInput
                        label="NSF"
                        value="NSF"
                        id="nsf"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("NSF")}
                    />
                    <CheckboxInput
                        label="Energy Star"
                        value="EnergyStar"
                        id="energyStar"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("EnergyStar")}
                    />
                    <CheckboxInput
                        label="ThinQ"
                        value="ThinQ"
                        id="thinQ"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("ThinQ")}
                    />
                    <CheckboxInput
                        label="InstaView"
                        value="InstaView"
                        id="instaview"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("InstaView")}
                    />
                    <CheckboxInput
                        label="WashTower"
                        value="WashTower"
                        id="washtower"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("WashTower")}
                    />
                    <CheckboxInput
                        label="Craft Ice"
                        value="CraftIce"
                        id="craftIce"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("CraftIce")}
                    />
                    <CheckboxInput
                        label="Door-in-Door Craft Ice"
                        value="DiDCraftIce"
                        id="diDCraftIce"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("DiDCraftIce")}
                    />
                    <CheckboxInput
                        label="Counter-Depth MAX"
                        value="CounterDepthMax"
                        id="counterDepthMax"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("CounterDepthMax")}
                    />
                    <CheckboxInput
                        label="Innit"
                        value="Innit"
                        id="innit"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("Innit")}
                    />
                    <CheckboxInput
                        label="SideChef"
                        value="SideChef"
                        id="sideChef"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("SideChef")}
                    />
                    <CheckboxInput
                        label="QuadWash Pro"
                        value="QuadWashPro"
                        id="quadWashPro"
                        type="checkbox"
                        onChange={handleLogoChange}
                        checked={selectedLogos.includes("QuadWashPro")}
                    />

                </PortalFormRow>
                <PortalSectionTextBox
                    title="File Upload"
                    headline="Optional"
                />
                <PortalFormImageUploadSection
                    label="Upload Product Image"
                    note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp (recommended). Image should have transparent background."
                >
                    <div className={styles.fimgwrap}>
                        <ImageUpload id="image" onInput={inputHandler} />
                    </div>
                </PortalFormImageUploadSection>
           

                {/* <PortalFormImageUploadSection
                    label="Upload Spec Sheet QR Code (English)"
                    note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp. Image should have transparent background."
                >
                    <div className={styles.fimgwrap}>
                        <ImageUpload id="qrCodeSpecSheetEN" onInput={inputHandler} />
                    </div>
                </PortalFormImageUploadSection>
                <PortalFormImageUploadSection
                    label="Upload Spec Sheet QR Code (French)"
                    note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp. Image should have transparent background."
                >
                    <div className={styles.fimgwrap}>
                        <ImageUpload id="qrCodeSpecSheetFR" onInput={inputHandler} />
                    </div>
                </PortalFormImageUploadSection> */}

                <ResourceFormSection initialSections={[]} onSectionsChange={setSections} />
                <PortalFormRow>
                    <div>
                        <TheButton
                            type='submit'
                            styleName="red"
                            animated="true"
                        >
                            Add Product
                        </TheButton>
                    </div>
                </PortalFormRow>
            </PortalForm>

        </>

    )
}

export default CreateProductPortalFormPage;