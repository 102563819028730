import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SFWProductsContext } from "../../../Context/CategoryContext";
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_MAXLENGTH, VALIDATOR_URL } from "../../../Utils/validators";
import { useForm } from "../../../Hooks/FormHook/form-hook";
import { useHttpClient } from "../../../Hooks/HttpHook/http-hook";
// import Loader from "../../../Shared/Components/Loaders/Loader";
import styles from "../CreateProduct/CreateProductPortalFormPage.module.css"


import Input from "../../../Shared/Components/UI/FormElements/Input/Input";

import CheckboxInput from "../../../Shared/Components/UI/FormElements/Checkbox/Checkbox";
import SelectInput from "../../../Shared/Components/UI/FormElements/Select/Select";

import { jwtDecode } from "jwt-decode";
import PortalForm from "../../PortalComponents/FormElements/PortalForm";
import PortalFormRow from "../../PortalComponents/FormElements/PortalFormRow";

import PortalSectionTextBox from "../../PortalComponents/PortalUi/PortalSectionTextBox/PortalSectionTextBox";
import PortalFormImageUploadSection from "../../PortalComponents/FormElements/PortalFormImageUploadSection";

import { ResourceFormSection } from "../../PortalComponents/FormElements/Dynamic/ResourceFormSection";


import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";





const UpdateProductPortalFormPage = () => {
    const defaultFormFields = {
        // specList1EN: [],
        // specList2EN: [],
        // specList3EN: [],
        // specList4EN: [],
        // specList1FR: [],
        // specList2FR: [],
        // specList3FR: [],
        // specList4FR: [],
        // videosEN: [],
        // videosFR: [],
        // upcCodeEN: [],
        // upcCodeFR: [],
        specList1EN: '',
        specList2EN: '',
        specList3EN: '',
        specList4EN: '',
        specList1FR: '',
        specList2FR: '',
        specList3FR: '',
        specList4FR: '',
        videosEN: '',
        videosFR: '',
        upcCodeEN: '',
        upcCodeFR: '',
    };
    const redirect = useNavigate();
    const { setProducts } = useContext(SFWProductsContext)
    const { setIsRoutingState, isRoutingState } = useContext(RoutingContext);
    const { setIsActiveSection, isPortalActionState } = useContext(AdminContext)

    const filePickerRef = useRef();
    const filePickerRefQrCodeSpecSheetEN = useRef();
    const filePickerRefQrCodeSpecSheetFR = useRef();

    const { sendRequest } = useHttpClient();

    const [showModal, setShowModal] = useState(false);
    const [isLoading] = useState(false);
    const [error] = useState();

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);

    // IMAGE STATE
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const [selectedQrCodeSpecSheetENFile, setSelectedQrCodeSpecSheetENFile] = useState(null);
    const [previewQRCodeSpecSheetENUrl, setPreviewQRCodeSpecSheetENUrl] = useState(null);

    const [selectedQrCodeSpecSheetFRFile, setSelectedQrCodeSpecSheetFRFile] = useState(null);
    const [previewQRCodeSpecSheetFRUrl, setPreviewQRCodeSpecSheetFRUrl] = useState(null);
    const [qrCodeImageRemovedFR, setQrCodeImageRemovedFR] = useState(false);
    const [qrCodeImageRemovedEN, setQrCodeImageRemovedEN] = useState(false);

    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [selectedLogos, setSelectedLogos] = useState([]);
    const [selectedColours, setSelectedColours] = useState([]);
    const [availability, setAvailability] = useState('');
    const [store, setStore] = useState('');

    const [sections, setSections] = useState([]);
    const [initialSections, setInitialSections] = useState([]);


    const [formField, setFormField] = useState(defaultFormFields);

    const [loadedProduct, setLoadedProduct] = useState()

    const { token } = useContext(AuthContext)
    const decodedToken = token ? jwtDecode(token) : null;

    // const productId = isEditingProduct
    const productId = isPortalActionState.editingProduct

    const pickImageHandler = () => {
        filePickerRef.current.click();
    }
    const pickQrCodeSpecSheetENImageHandler = () => {
        filePickerRefQrCodeSpecSheetEN.current.click();
    }
    const pickQrCodeSpecSheetFRImageHandler = () => {
        filePickerRefQrCodeSpecSheetFR.current.click();
    }

    const [formState, inputHandler, setFormData] = useForm({
        title: {
            value: '',
            isValid: false
        },
        msrp: {
            value: '',
            isValid: false
        },
        subtitleEN: {
            value: '',
            isValid: false
        },
        subtitleFR: {
            value: '',
            isValid: false
        },
        // upcCodeEN: {
        //     value: '',
        //     isValid: false
        // },
        // upcCodeFR: {
        //     value: '',
        //     isValid: false
        // },
        installGuideLinkEN: {
            value: '',
            isValid: true
        },
        installGuideLinkFR: {
            value: '',
            isValid: true
        },
        ownerManualLinkEN: {
            value: '',
            isValid: true
        },
        ownerManualLinkFR: {
            value: '',
            isValid: true
        },
        specSheetLinkEN: {
            value: '',
            isValid: true
        },
        specSheetLinkFR: {
            value: '',
            isValid: true
        },
        // videosEN: {
        //     value: '',
        //     isValid: true
        // },
        // videosFR: {
        //     value: '',
        //     isValid: true
        // },
    },
        false
    );

    const handleChange = (sfw) => {
        const { name, value } = sfw.target;
        setFormField(prevFormField => ({ ...prevFormField, [name]: value }));
    };

    //IMAGE FILE + PREVIEW HANDLER  
    const handleFileChange = (event) => {
        if (!event.target.files || event.target.files.length === 0) { //NEW
            return;
        }
        const file = event.target.files[0];
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
    };

    const handleFileChangeQrCodeSpecSheetEN = (event) => {
        if (!event.target.files || event.target.files.length === 0) { //NEW
            return;
        }
        const file = event.target.files[0];
        setSelectedQrCodeSpecSheetENFile(file);
        setPreviewQRCodeSpecSheetENUrl(URL.createObjectURL(file));
    };

    // NEW
    const clearQrCodeSpecSheetENFileHandler = () => {
        filePickerRefQrCodeSpecSheetEN.current.value = '';
        setSelectedQrCodeSpecSheetENFile(null);
        setPreviewQRCodeSpecSheetENUrl(null);
        setLoadedProduct(prevState => ({ ...prevState, qrCodeSpecSheetEN: null }))
        setQrCodeImageRemovedEN(true)

        console.log('selected qr code', selectedQrCodeSpecSheetENFile, 'selectedqrcodeurlpreview', previewQRCodeSpecSheetENUrl)
    };



    const handleFileChangeQrCodeSpecSheetFR = (event) => {
        if (!event.target.files || event.target.files.length === 0) { //NEW
            return;
        }
        const file = event.target.files[0];
        setSelectedQrCodeSpecSheetFRFile(file);
        setPreviewQRCodeSpecSheetFRUrl(URL.createObjectURL(file));
    };

    const clearQrCodeSpecSheetFRFileHandler = () => {
        filePickerRefQrCodeSpecSheetFR.current.value = '';
        setSelectedQrCodeSpecSheetFRFile(null);
        setPreviewQRCodeSpecSheetFRUrl(null);
        setLoadedProduct(prevState => ({ ...prevState, qrCodeSpecSheetFR: null }));
        setQrCodeImageRemovedFR(true)


        console.log('selected qr code', selectedQrCodeSpecSheetFRFile, 'selectedqrcodeurlpreview', previewQRCodeSpecSheetFRUrl)
    };

    // useEffect(() => {
    //     if (!selectedQrCodeSpecSheetFRFile && !previewQRCodeSpecSheetFRUrl) {
    //         console.log('File and preview cleared');
    //         // Additional cleanup logic if needed

    //     }
    // }, [selectedQrCodeSpecSheetFRFile, previewQRCodeSpecSheetFRUrl]);

    //LOGO HANDLER
    const handleLogoChange = (value, checked) => {
        if (checked) {
            setSelectedLogos([...selectedLogos, value]);
        } else {
            setSelectedLogos(selectedLogos.filter(logo => logo !== value));
        }
    };
    //COLOUR HANDLER
    const handleColourChange = (value, checked) => {
        if (checked) {
            setSelectedColours([...selectedColours, value]);
        } else {
            setSelectedColours(selectedColours.filter(colour => colour !== value));
        }
    };
    const handleAvailabilityChange = (event) => {
        setAvailability(event.target.value);
    }
    const handleStoreChange = (event) => {
        setStore(event.target.value);
    };
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setSubcategory(""); // Reset the subcategory when category changes
    };
    const handleSubCategoryChange = (e) => {
        setSubcategory(e.target.value);

    };



    useEffect(() => {
        const fetchRequest = async () => {
            try {
                const data = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/product-details/${productId}`)
                setLoadedProduct(data.product);


                setPreviewUrl(`${process.env.REACT_APP_AWS_URL}/${data.product.image}`);
                setPreviewQRCodeSpecSheetENUrl(`${process.env.REACT_APP_BACKEND_URL}/${data.product.qrCodeSpecSheetEN}`);
                setPreviewQRCodeSpecSheetFRUrl(`${process.env.REACT_APP_BACKEND_URL}/${data.product.qrCodeSpecSheetFR}`);

                setSelectedLogos(data.product.logos);
                setSelectedColours(data.product.colourEN);
                setAvailability(data.product.availability);
                setStore(data.product.store);
                setCategory(data.product.categoryEN);
                setSubcategory(data.product.subcategoryEN);

                // Initialize sections with both the original file path and full preview URL
                const initializedSections = (data.product?.sections || []).map(section => ({
                    ...section,
                    originalResourceQrCodeImageEN: section.resourceQrCodeImageEN,
                    originalResourceQrCodeImageFR: section.resourceQrCodeImageFR,
                    resourceQrCodeImageEN: section.resourceQrCodeImageEN
                        ? `${process.env.REACT_APP_AWS_URL}/${section.resourceQrCodeImageEN}`  // Full URL for preview
                        : '', // Default to an empty string if no image
                    resourceQrCodeImageFR: section.resourceQrCodeImageFR
                        ? `${process.env.REACT_APP_AWS_URL}/${section.resourceQrCodeImageFR}`  // Full URL for preview
                        : ''  // Default to an empty string if no image
                }));


                setInitialSections(initializedSections);
                setSections(initializedSections);

                setFormField({
                    specList1EN: data.product.specList1EN.join('\n') || '',
                    specList2EN: data.product.specList2EN.join('\n') || '',
                    specList3EN: data.product.specList3EN.join('\n') || '',
                    specList4EN: data.product.specList4EN.join('\n') || '',
                    specList1FR: data.product.specList1FR.join('\n') || '',
                    specList2FR: data.product.specList2FR.join('\n') || '',
                    specList3FR: data.product.specList3FR.join('\n') || '',
                    specList4FR: data.product.specList4FR.join('\n') || '',

                    videosEN: data.product.videosEN.join('\n') || '',
                    videosFR: data.product.videosFR.join('\n') || '',

                    upcCodeEN: data.product.upcCodeEN.join('\n') || '',
                    upcCodeFR: data.product.upcCodeFR.join('\n') || '',
                });
                setFormData({
                    title: {
                        value: data.product.title,
                        isValid: true
                    },
                    msrp: {
                        value: data.product.msrp,
                        isValid: true
                    },
                    subtitleEN: {
                        value: data.product.subtitleEN,
                        isValid: true
                    },
                    subtitleFR: {
                        value: data.product.subtitleFR,
                        isValid: true
                    },
                    // upcCodeEN: {
                    //     value: data.product.upcCodeEN,
                    //     isValid: true
                    // },
                    // upcCodeFR: {
                    //     value: data.product.upcCodeFR,
                    //     isValid: true
                    // },
                    installGuideLinkEN: {
                        value: data.product.installGuideLinkEN,
                        isValid: true
                    },
                    installGuideLinkFR: {
                        value: data.product.installGuideLinkFR,
                        isValid: true
                    },
                    ownerManualLinkEN: {
                        value: data.product.ownerManualLinkEN,
                        isValid: true
                    },
                    ownerManualLinkFR: {
                        value: data.product.ownerManualLinkFR,
                        isValid: true
                    },
                    specSheetLinkEN: {
                        value: data.product.specSheetLinkEN,
                        isValid: true
                    },
                    specSheetLinkFR: {
                        value: data.product.specSheetLinkFR,
                        isValid: true
                    },
                    // videosEN: {
                    //     value: Array.isArray(data.product.videosEN) ? data.product.videosEN.join('\n') : data.product.videosEN,
                    //     isValid: true
                    // },
                    // videosFR: {
                    //     value: Array.isArray(data.product.videosFR) ? data.product.videosFR.join('\n') : data.product.videosFR,
                    //     isValid: true
                    // },
                    // videosEN: {
                    //     value: Array.isArray(data.product.videosEN) ? data.product.videosEN.split('\n') || '' : data.product.videosEN,
                    //     isValid: true
                    // },
                    // videosFR: {
                    //     value: Array.isArray(data.product.videosFR) ? data.product.videosFR.split('\n') || '' : data.product.videosFR,
                    //     isValid: true
                    // },
                    image: {
                        value: data.product.image,
                        isValid: true
                    },
                    qrCodeSpecSheetEN: {
                        value: data.product.qrCodeSpecSheetEN,
                        isValid: true
                    },
                    qrCodeSpecSheetFR: {
                        value: data.product.qrCodeSpecSheetFR,
                        isValid: true
                    },
                },
                    true
                );
            } catch (err) { }
        }
        fetchRequest();
        console.log('render')
        // }, [sendRequest, productId, setFormData, setFormField])
    }, [sendRequest, productId, setFormData, setFormField])



    // console.log('sections', sections)
    // console.log('form fields', formField)
    // console.log('loaded product',loadedProduct)
    //DATE - AVAILABILITY FUNCTION
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    const subcategoryOptions = {

        "LG Studio": [ //PHASE 2 UPDATED
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Built-in Cooking", label: "Built-in Cooking" }, //NEW
            { value: "Free-standing Cooking", label: "Free-standing Cooking" }, //NEW
            { value: "Ventilation", label: "Ventilation" },  //NEW
            { value: "Refrigeration", label: "Refrigeration" },
            { value: "Dishwashers", label: "Dishwashers" },
            { value: "Laundry", label: "Laundry" },
        ],
        "Laundry": [  //PHASE 2 NO CHANGE
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Front Load", label: "Front Load" },
            { value: "WashTower", label: "WashTower" },
            { value: "Top Load", label: "Top Load" },
            { value: "Specialty Laundry", label: "Specialty Laundry" },
            { value: "Accessories", label: "Accessories" }
        ],
        "Refrigeration": [ //PHASE 2 UPDATED
            { value: "", label: "Select Subcategory", disabled: true },
            { value: `36" French Door Counter Depth`, label: `36" French Door Counter Depth` }, //NEW
            { value: `36" French Door Standard Depth`, label: `36" French Door Standard Depth` }, //NEW
            { value: `33" French Door Counter Depth`, label: `33" French Door Counter Depth` }, //NEW
            { value: `33" French Door Standard Depth`, label: `33" French Door Standard Depth` }, //NEW
            { value: `30" French Door`, label: `30" French Door` }, //NEW
            { value: "Side-by-Side", label: "Side-by-Side" },
            // { value: "Top Mount", label: "Top Mount" },
            { value: "Top and Bottom Mount", label: "Top and Bottom Mount" },
            { value: "Column", label: "Column" },
            { value: "Kimchi", label: "Kimchi" },
        ],

        "Cooking": [//PHASE 2 UPDATED
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Electric Slide-in Ranges", label: "Electric Slide-in Ranges" },  //NEW
            { value: "Electric Free-standing Ranges", label: "Electric Free-standing Ranges" },  //NEW
            { value: "Induction Ranges", label: "Induction Ranges" },  //NEW
            { value: "Gas Ranges", label: "Gas Ranges" },
            { value: "Wall Ovens", label: "Wall Ovens" }, //NEW NAME 
            { value: "Cooktops", label: "Cooktops" }, //NEW NAME 
            { value: "Over-the-Range Microwaves", label: "Over-the-Range Microwaves" },
            { value: "Countertop Microwaves", label: "Countertop Microwaves" },
        ],
        "Dishwasher": [ //PHASE 2 NAME CHANGE FOR SUB
            { value: "", label: "Select Subcategory", disabled: true },
            { value: "Built-in", label: "Built-in" },
        ]

    };
    const coloumTitleCategory = {
        "Laundry": {// PHASE 2 NO CHANGE
            'Front Load': [
                { value: "Specifications", label: "Specifications" },

                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
                { value: "Matching Add-ons", label: "Matching Add-ons" },
            ],
            'Top Load': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
                { value: "Matching Add-ons", label: "Matching Add-ons" },
            ],
            'Specialty Laundry': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
                { value: "Matching Add-ons", label: "Matching Add-ons" },
            ],
            'WashTower': [
                { value: "Specifications", label: "Specifications" },
                { value: "Innovations", label: "Innovations" },
            ],
            // NEW
            'Accessories': [
                { value: "Specifications", label: "Specifications" },
            ],
        },
        "Refrigeration": {  // PHASE 2 UPDATED

            "36\" French Door Counter Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            "36\" French Door Standard Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },

                { value: "Performance", label: "Performance" },
            ],
            "33\" French Door Counter Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            "33\" French Door Standard Depth": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            "30\" French Door": [ // NEW
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            'Side-by-Side': [
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },

                { value: "Performance", label: "Performance" },
            ],
            'Top and Bottom Mount': [ //COMBINE WITH BOTTOM MOUNT
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            'Kimchi': [
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
            ],
            'Column': [
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Organization", label: "Organization" },
                { value: "Performance", label: "Performance" },
                { value: "Optional Panels", label: "Optional Panels" },
            ],
        },

        "Cooking": { // PHASE 2 UPDATED
            'Electric Slide-in Ranges': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Electric Free-standing Ranges': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Induction Ranges': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Gas Ranges': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],
            'Over-the-Range Microwaves': [
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },

            ],

            'Wall Ovens': [ //NEW NAME
                { value: "Specifications", label: "Specifications" },
                { value: "Stylish Design", label: "Stylish Design" },
                { value: "Innovations", label: "Innovations" },
            ],

            'Cooktops': [ //NEW NAME
                { value: "Specifications", label: "Specifications" },
            ],
            'Countertop Microwaves': [
                { value: "Specifications", label: "Specifications" },
                { value: "Optional Trim Kit", label: "Optional Trim Kit" },
            ],
        },
        "Dishwasher": { // NAME CHANGE REQUESTED
            // 'Dishwasher': [
            'Built-in': [
                { value: "Specifications", label: "Specifications" },
                { value: "Innovations", label: "Innovations" },
                { value: "Stylish Design", label: "Stylish Design" },
            ],
        },

        "LG Studio": { // PHASE 2 UPDATED
            'Built-in Cooking': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Free-standing Cooking': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Ventilation': [ //NEW
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Refrigeration': [
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Dishwashers': [
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
            'Laundry': [
                { value: "Specifications", label: "Specifications" },
                { value: "LG Studio Exclusive", label: "LG Studio Exclusive" },
            ],
        }

    };


    const productUpdateRequiredHandler = (e) => {
        e.preventDefault();


        const normalizeString = (value) => value && value.trim().toLowerCase();

        const hasSectionChanged = (newSection, originalSection) => {
            // Compare resourceTitle and resourceUrl
            if (
                normalizeString(newSection.resourceTitleEN) !== normalizeString(originalSection.resourceTitleEN) ||
                normalizeString(newSection.resourceTitleFR) !== normalizeString(originalSection.resourceTitleFR) ||
                normalizeString(newSection.resourceUrlEN) !== normalizeString(originalSection.resourceUrlEN) ||
                normalizeString(newSection.resourceUrlFR) !== normalizeString(originalSection.resourceUrlFR)
            ) {
                return true;
            }

            // Compare resourceQrCodeImage
            const newImageEN = newSection.resourceQrCodeImageEN;
            const originalImageEN = originalSection.resourceQrCodeImageEN;

            const newImageFR = newSection.resourceQrCodeImageFR;
            const originalImageFR = originalSection.resourceQrCodeImageFR;

            // Check if either is a File object and if their references have changed
            if (
                (newImageEN instanceof File && newImageEN !== originalImageEN) ||
                (originalImageEN instanceof File && newImageEN !== originalImageEN) ||
                (newImageFR instanceof File && newImageFR !== originalImageFR) ||
                (originalImageFR instanceof File && newImageFR !== originalImageFR)
            ) {
                return true;
            }

            // Otherwise, compare as strings (URLs)
            return (
                normalizeString(newImageEN) !== normalizeString(originalImageEN) ||
                normalizeString(newImageFR) !== normalizeString(originalImageFR)
            );
        };
        // CHECK IF SECTIONS CHANGED
        const haveSectionsChanged = (newSections, originalSections) => {
            // If the lengths are different, sections have changed
            if (newSections.length !== originalSections.length) return true;
            // Compare each section
            return newSections.some((newSection, index) => hasSectionChanged(newSection, originalSections[index]));
        }

        const arrayValuesChanged = (newValues, originalValues) => {

            return newValues.join('\n') !== originalValues.join('\n');
        }
        // Convert msrp values to string
        const currentMsrp = String(formState.inputs.msrp.value).trim();
        const originalMsrp = String(loadedProduct.msrp).trim();


        const hasFrQRCodeChanged = (
            (qrCodeImageRemovedFR === true && previewQRCodeSpecSheetFRUrl === null)
            // || ( qrCodeImageRemovedEN === false && selectedQrCodeSpecSheetENFile !== null)
            || (qrCodeImageRemovedFR === false && selectedQrCodeSpecSheetFRFile !== null && loadedProduct.qrCodeSpecSheetFR === false) // WILL NOT TRIGGER IF UPLOADED NOT AVAILABLE IF NO IMAGE ON LOAD

        );
        const hasENQRCodeChanged = (

            (qrCodeImageRemovedEN === true && previewQRCodeSpecSheetENUrl === null)
            // || ( qrCodeImageRemovedEN === false && selectedQrCodeSpecSheetENFile !== null)
            || (qrCodeImageRemovedEN === false && selectedQrCodeSpecSheetENFile !== null && loadedProduct.qrCodeSpecSheetEN === false) // WILL NOT TRIGGER IF UPLOADED NOT AVAILABLE IF NO IMAGE ON LOAD

        );

        console.log(hasFrQRCodeChanged)
        const hasChanges = (
            formState.inputs.title.value !== loadedProduct.title ||
            currentMsrp !== originalMsrp ||
            // formState.inputs.msrp.value !== loadedProduct.msrp ||
            formState.inputs.subtitleEN.value !== loadedProduct.subtitleEN ||
            formState.inputs.subtitleFR.value !== loadedProduct.subtitleFR ||

            // formState.inputs.upcCodeEN.value !== loadedProduct.upcCodeEN ||
            // formState.inputs.upcCodeFR.value !== loadedProduct.upcCodeFR ||
            formState.inputs.installGuideLinkEN.value !== loadedProduct.installGuideLinkEN ||
            formState.inputs.installGuideLinkFR.value !== loadedProduct.installGuideLinkFR ||

            formState.inputs.ownerManualLinkEN.value !== loadedProduct.ownerManualLinkEN ||
            formState.inputs.ownerManualLinkFR.value !== loadedProduct.ownerManualLinkFR ||
            formState.inputs.specSheetLinkEN.value !== loadedProduct.specSheetLinkEN ||
            formState.inputs.specSheetLinkFR.value !== loadedProduct.specSheetLinkFR ||

            // Handling array values:
            arrayValuesChanged(selectedLogos, loadedProduct.logos) ||
            arrayValuesChanged(selectedColours, loadedProduct.colourEN) ||
            arrayValuesChanged(formField.specList1EN.split('\n'), loadedProduct.specList1EN) ||
            arrayValuesChanged(formField.specList2EN.split('\n'), loadedProduct.specList2EN) ||
            arrayValuesChanged(formField.specList3EN.split('\n'), loadedProduct.specList3EN) ||
            arrayValuesChanged(formField.specList4EN.split('\n'), loadedProduct.specList4EN) ||
            arrayValuesChanged(formField.specList1FR.split('\n'), loadedProduct.specList1FR) ||
            arrayValuesChanged(formField.specList2FR.split('\n'), loadedProduct.specList2FR) ||
            arrayValuesChanged(formField.specList3FR.split('\n'), loadedProduct.specList3FR) ||
            arrayValuesChanged(formField.specList4FR.split('\n'), loadedProduct.specList4FR) ||
            arrayValuesChanged(formField.videosEN.split('\n'), loadedProduct.videosEN) ||
            arrayValuesChanged(formField.videosFR.split('\n'), loadedProduct.videosFR) ||
            arrayValuesChanged(formField.upcCodeEN.split('\n'), loadedProduct.upcCodeEN) ||
            arrayValuesChanged(formField.upcCodeFR.split('\n'), loadedProduct.upcCodeFR) ||
            haveSectionsChanged(sections, initialSections) ||
            category !== loadedProduct.categoryEN ||
            subcategory !== loadedProduct.subcategoryEN ||
            store !== loadedProduct.store ||
            availability !== loadedProduct.availability ||
            selectedFile !== null
            || hasFrQRCodeChanged
            || hasENQRCodeChanged
            // || selectedQrCodeSpecSheetFRFile !== null && loadedProduct.qrCodeSpecSheetFR === null

            // selectedQrCodeSpecSheetENFile !== null ||
            // selectedQrCodeSpecSheetENFile !== loadedProduct.qrCodeSpecSheetEN ||
            // selectedQrCodeSpecSheetFRFile !== null ||
            // QR Code FR change detection
            // QR Code FR change detection

        );
        console.log('has EN qr changed', loadedProduct.qrCodeSpecSheetEN, selectedQrCodeSpecSheetENFile)
        console.log('has FR qr changed', loadedProduct.qrCodeSpecSheetFR, selectedQrCodeSpecSheetFRFile)
        if (!hasChanges) {
            setModalInfo({
                show: true,
                title: 'Nothing to update',
                message: `Product information hasn't changed. Update not required.`,
                errorList: [],
                confirmText: 'Go back',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            })
            return;

        }
        setModalInfo({
            show: true,
            title: 'Are you sure you want to make changes?',
            message: `You are about to make changes to product. Please confirm.`,
            confirmText: "Update product",
            onConfirm: productUpdateHandler,
            cancelText: "Cancel",
            onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            onClose: () => setModalInfo({ ...modalInfo, show: false }),
        });

    }



    const productUpdateSuccessHanlder = () => {
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(2)
        redirect('/admin')
    }


    const productUpdateHandler = async (event) => {
        event.preventDefault();

        // setIsLoading(true)
        setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: true
        }))

        let errorMessage = [];
        if (!formState.inputs.msrp.value) errorMessage.push('Price missing or invalid.');
        if (!formState.inputs.title.value) errorMessage.push('Title missing or invalid.');
        if (!category) errorMessage.push('Category missing or invalid.');
        if (selectedColours.length === 0 || (selectedColours.length === 1 && selectedColours[0] === "")) {
            errorMessage.push('Colour missing or invalid.');
        }
        if (!subcategory) errorMessage.push('Subcategory missing or invalid.');
        if (!store) errorMessage.push('Store missing or invalid.');
        if (!availability) errorMessage.push('Availability missing or invalid.');

        if (!formState.inputs.subtitleEN.value || !formState.inputs.subtitleFR.value) errorMessage.push('Subtitle missing or invalid.')

        // if (!formState.inputs.upcCodeEN.value || !formState.inputs.upcCodeFR.value) errorMessage.push('UPC codes missing or invalid.')
        if (formField.upcCodeEN.length === 0 || formField.upcCodeFR.length === 0) errorMessage.push('UPC Codes missing or invalid.');

        if (!formState.inputs.image.value) errorMessage.push('Image missing or invalid.')
        if (errorMessage.length > 0) {
            // setIsLoading(false)
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Update failed',
                message: 'Please fix the errors below.',
                errorList: errorMessage,
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setShowModal(true)
            // setError({
            //     title: 'Please fix errors.',
            //     errorList: errorMessage
            // });
            return;
        }

        try {
            const formData = new FormData();
            formData.append('store', store)
            formData.append('availability', availability)
            formData.append('category', category)
            formData.append('subcategory', subcategory)

            formField.videosEN.split('\n').forEach(item => {
                formData.append('videosEN[]', item);
            });
            formField.videosFR.split('\n').forEach(item => {
                formData.append('videosFR[]', item);
            });
            formField.upcCodeEN.split('\n').forEach(item => {
                formData.append('upcCodeEN[]', item);
            });
            formField.upcCodeFR.split('\n').forEach(item => {
                formData.append('upcCodeFR[]', item);
            });

            formField.specList1EN.split('\n').forEach(item => {
                formData.append('specList1EN[]', item);
            });

            formField.specList2EN.split('\n').forEach(item => {
                formData.append('specList2EN[]', item);
            });

            formField.specList3EN.split('\n').forEach(item => {
                formData.append('specList3EN[]', item);
            });

            formField.specList4EN.split('\n').forEach(item => {
                formData.append('specList4EN[]', item);
            });

            formField.specList1FR.split('\n').forEach(item => {
                formData.append('specList1FR[]', item);
            });

            formField.specList2FR.split('\n').forEach(item => {
                formData.append('specList2FR[]', item);
            });

            formField.specList3FR.split('\n').forEach(item => {
                formData.append('specList3FR[]', item);
            });

            formField.specList4FR.split('\n').forEach(item => {
                formData.append('specList4FR[]', item);
            })

            formData.append('title', formState.inputs.title.value);
            formData.append('msrp', formState.inputs.msrp.value);
            formData.append('subtitleEN', formState.inputs.subtitleEN.value);
            formData.append('subtitleFR', formState.inputs.subtitleFR.value);
            // formData.append('upcCodeEN', formState.inputs.upcCodeEN.value);
            // formData.append('upcCodeFR', formState.inputs.upcCodeFR.value);
            formData.append('installGuideLinkEN', formState.inputs.installGuideLinkEN.value);
            formData.append('installGuideLinkFR', formState.inputs.installGuideLinkFR.value);

            formData.append('specSheetLinkEN', formState.inputs.specSheetLinkEN.value);
            formData.append('specSheetLinkFR', formState.inputs.specSheetLinkFR.value);

            formData.append('ownerManualLinkEN', formState.inputs.ownerManualLinkEN.value);
            formData.append('ownerManualLinkFR', formState.inputs.ownerManualLinkFR.value);



            formData.append('image', selectedFile || loadedProduct.image);
            formData.append('creator', decodedToken.userId);

            if (selectedQrCodeSpecSheetENFile) {
                // Append the new file if one is selected
                formData.append('qrCodeSpecSheetEN', selectedQrCodeSpecSheetENFile);
            } else if (previewQRCodeSpecSheetENUrl === null) {
                // Append null if the image has been cleared
                formData.append('qrCodeSpecSheetEN', null);
            } else {
                // Otherwise, append the loaded product's existing image
                formData.append('qrCodeSpecSheetEN', loadedProduct.qrCodeSpecSheetEN);
            }

            if (selectedQrCodeSpecSheetFRFile) {
                // Append the new file if one is selected
                formData.append('qrCodeSpecSheetFR', selectedQrCodeSpecSheetFRFile);
            } else if (previewQRCodeSpecSheetFRUrl === null) {
                // Append null if the image has been cleared
                formData.append('qrCodeSpecSheetFR', null);
            } else {
                // Otherwise, append the loaded product's existing image
                formData.append('qrCodeSpecSheetFR', loadedProduct.qrCodeSpecSheetFR);
            }
            // formData.append('qrCodeSpecSheetFR', selectedQrCodeSpecSheetFRFile || null);
            // formData.append('qrCodeSpecSheetFR', selectedQrCodeSpecSheetFRFile || loadedProduct.qrCodeSpecSheetFR);
            for (const logo of selectedLogos) {
                formData.append('logos', logo);
            }
            for (const colour of selectedColours) {
                formData.append('colour', colour);
            }
            // NEW
            sections.forEach((section, index) => {
                formData.append(`sections[${index}][resourceTitleEN]`, section.resourceTitleEN);
                formData.append(`sections[${index}][resourceUrlEN]`, section.resourceUrlEN);

                if (section.resourceQrCodeImageEN instanceof File) {
                    // New file selected
                    formData.append(`sections[${index}][resourceQrCodeImageEN]`, section.resourceQrCodeImageEN);
                } else if (section.resourceQrCodeImageEN) {
                    // Original image path should be preserved
                    formData.append(`sections[${index}][resourceQrCodeImageEN]`, section.originalResourceQrCodeImageEN);
                }

                formData.append(`sections[${index}][resourceTitleFR]`, section.resourceTitleFR);
                formData.append(`sections[${index}][resourceUrlFR]`, section.resourceUrlFR);

                if (section.resourceQrCodeImageFR instanceof File) {
                    // New file selected
                    formData.append(`sections[${index}][resourceQrCodeImageFR]`, section.resourceQrCodeImageFR);
                } else if (section.resourceQrCodeImageFR) {
                    // Original image path should be preserved
                    formData.append(`sections[${index}][resourceQrCodeImageFR]`, section.originalResourceQrCodeImageFR);
                }
            });

            try {
                // const response = await sendRequest(`http://localhost:3000/edit-product/${productId}`,
                    const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/edit-product/${productId}`,
                    // const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/edit-product/${productId}`,
                    'PATCH',
                    formData,
                    { 'Authorization': `Bearer ${token}` }
                );

                if (response.message === "Product updated") {
                    setIsRoutingState(prevState => ({
                        ...prevState,
                        isLoading: false
                    }))
                    setProducts(prevProducts =>
                        prevProducts.map(product =>
                            product._id === productId ? response.updatedProduct : product
                        ));
                    setModalInfo({
                        show: true,
                        title: 'Product updated',
                        message: 'The product has been updated successfully!',
                        errorList: [],
                        confirmText: 'Close',
                        wasSuccessful: true,
                        onConfirm: productUpdateSuccessHanlder,
                        onClose: productUpdateSuccessHanlder
                    });
                }
                if (!response.message === "Product updated") {
                    setIsRoutingState(prevState => ({
                        ...prevState,
                        isLoading: false
                    }))
                    setModalInfo({
                        show: true,
                        title: 'Update failed',
                        message: 'The product could not be updated. Please try again.',
                        errorList: [],
                        confirmText: 'Try again',
                        onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                        onClose: () => setModalInfo({ ...modalInfo, show: false }),
                    });
                }

            } catch (error) {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false
                }))
                setModalInfo({
                    show: true,
                    title: 'Something went wrong',
                    message: 'The product could not be updated. Please contact an administrator for assistance.',
                    errorList: [],
                    confirmText: 'Try again',
                    onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });

            }
        } catch (err) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'Please contact an administrator.',
                errorList: [],
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });

        }
    };


    return (
        <>
            <Modal
                icon
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />


            {!isRoutingState.isLoading && loadedProduct &&
                <PortalForm title="Update Product"
                    subtitle="Admin Portal"
                    headline={`Update ${loadedProduct?.title}`}
                    onSubmit={productUpdateRequiredHandler}
                >
                    <PortalFormRow >
                        <Input
                            id="title"
                            type="text"
                            element="input"
                            label="Title"
                            validators={[VALIDATOR_MINLENGTH(5)]}
                            errorText="5 character minimum."
                            onInput={inputHandler}
                            initialValue={loadedProduct.title}
                            initialIsValid={formState.inputs.title.isValid}
                        />
                        <Input
                            id="msrp"
                            type="number"
                            element="input"
                            label="MSRP"
                            validators={[VALIDATOR_MINLENGTH(1), VALIDATOR_MAXLENGTH(10)]}
                            errorText="MSRP required. 10 digit max."
                            onInput={inputHandler}
                            initialValue={loadedProduct.msrp}
                            initialIsValid={formState.inputs.msrp.isValid}
                        />
                    </PortalFormRow>
                    <PortalFormRow>
                        <Input
                            id="subtitleEN"
                            type="text"
                            element="input"
                            label="Subtitle (English)"
                            validators={[VALIDATOR_MINLENGTH(8), VALIDATOR_MAXLENGTH(300)]}
                            errorText="Must be between 8-300 characters."
                            onInput={inputHandler}
                            initialValue={loadedProduct.subtitleEN}
                            // initialValue={formState.inputs.subtitleEN.value}
                            initialIsValid={formState.inputs.subtitleEN.isValid}
                        />
                        <Input
                            id="subtitleFR"
                            type="text"
                            element="input"
                            label="Subtitle (French)"
                            validators={[VALIDATOR_MINLENGTH(5)]}
                            errorText="5 character minimum."
                            onInput={inputHandler}
                            initialValue={loadedProduct.subtitleFR}
                            // initialValue={formState.inputs.subtitleFR.value}
                            initialIsValid={formState.inputs.subtitleFR.isValid}
                        />
                    </PortalFormRow>
                    <PortalSectionTextBox
                        title="Category Selection"
                        headline="Once a category is selected the sub category options will appear."
                    />
                    <PortalFormRow>
                        <SelectInput
                            id="category"
                            name="category"
                            value={category}
                            validators={[VALIDATOR_REQUIRE()]}
                            labelName="Category"
                            onChange={handleCategoryChange}
                            options={[
                                { value: "", label: "Select Category", disabled: true },
                                { value: "LG Studio", label: "LG Studio" },
                                { value: "Laundry", label: "Laundry" },
                                { value: "Refrigeration", label: "Refrigeration" },
                                { value: "Cooking", label: "Cooking" },
                                { value: "Dishwasher", label: "Dishwasher" }
                            ]}

                        />
                        {category && (

                            <SelectInput
                                id="subcategory"
                                name="subcategory"
                                value={subcategory}

                                validators={[VALIDATOR_REQUIRE()]}
                                onChange={handleSubCategoryChange}
                                options={subcategoryOptions[category]}
                                labelName="Subcategory"
                            />
                        )}
                    </PortalFormRow>
                    <PortalSectionTextBox
                        title="Technical Specifications"
                        headline="The title for each column is based on the selected category / subcategory. To populate a product's 'Related Products' carousel, use special keyword(s) 'optional', 'matching', and/or 'accessories', and wrap the target product(s) in brackets. Insert brackets around the title of the accessory you want to feature as a related product. ie. Matching washer (WM6998H_A), Optional (WM9500HKA,WD205CK, LUWM101HWA), Accessories (SWWE50N3 SWWG50N3, DLEX9500K / DLGX9501K, DLEX4000_ DLGX4001_) Minimum completion of 1 column in English and French."
                    />
                    {/* <PortalFormRow> */}
                    {
                        category && subcategory && coloumTitleCategory[category]
                            && coloumTitleCategory[category][subcategory] ?
                            coloumTitleCategory[category][subcategory].map((item, index) => {
                                index++; // increment index here
                                //    console.log('form input',formField[`specList${index}EN`])
                                //     const newArray = formField[`specList${index}EN`].join("\n")
                                //    console.log('new array',newArray) 
                                return (
                                    <>


                                        <div key={item.value}>
                                            <PortalFormRow>
                                                <div className={styles.specsTextAreaWrapper}>
                                                    <div className={styles.labelTextWrapper}>
                                                        <p className={styles.labelText}>
                                                            {item.label} {`(English)`}
                                                        </p>
                                                        <p className={styles.labelInfoText}>
                                                            Select the text and update one per line. Do not add additional spaces or hit enter to get to the next line.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <textarea
                                                            rows={20}
                                                            // id={`specTitle1`}
                                                            type="text"
                                                            value={formField[`specList${index}EN`] || ''} // Use formField for input value
                                                            onChange={handleChange}  // Add onChange handler to update formField when input changes
                                                            name={`specList${index}EN`}  // Add name property to match formField keys
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.specsTextAreaWrapper}>
                                                    <div className={styles.labelTextWrapper}>
                                                        <p className={styles.labelText}>
                                                            {item.label} {`(French)`}
                                                        </p>
                                                        <p className={styles.labelInfoText}>
                                                            Select the text and update one per line. Do not add additional spaces or hit enter to get to the next line.
                                                        </p>
                                                    </div>
                                                    <textarea
                                                        rows={20}
                                                        // id={`specTitle1`}
                                                        type="text"
                                                        value={formField[`specList${index}FR`] || ''} // Use formField for input value
                                                        onChange={handleChange}  // Add onChange handler to update formField when input changes
                                                        name={`specList${index}FR`}  // Add name property to match formField keys
                                                    />
                                                </div>
                                            </PortalFormRow>
                                        </div>
                                    </>
                                )
                            }) : null
                    }
                    {/* </PortalFormRow> */}
                    <PortalFormRow>
                        <div className={styles.specsTextAreaWrapper}>
                            <div className={styles.labelTextWrapper}>
                                <p className={styles.labelText}>UPC Codes {`(English)`} </p>
                                <p className={styles.labelInfoText}>
                                    Select the text and update one per line. Do not add additional spaces or hit enter to get to the next line.
                                </p>
                            </div>
                            <textarea
                                rows={10}
                                id="upcCodeEN"
                                type="text"
                                value={formField[`upcCodeEN`] || ''}
                                onChange={handleChange}
                                name={`upcCodeEN`}
                            />
                        </div>
                        <div className={styles.specsTextAreaWrapper}>
                            <div className={styles.labelTextWrapper}>
                                <p className={styles.labelText}>UPC Codes {`(French)`} </p>
                                <p className={styles.labelInfoText}>
                                    Select the text and update one per line. Do not add additional spaces or hit enter to get to the next line.
                                </p>
                            </div>
                            <textarea
                                rows={10}
                                id="upcCodeFR"
                                type="text"
                                value={formField[`upcCodeFR`] || ''}
                                onChange={handleChange}
                                name={`upcCodeFR`}
                            />
                        </div>
                    </PortalFormRow>

                    <PortalFormRow>
                        <SelectInput
                            id="store"
                            name="store"
                            value={store}
                            validators={[VALIDATOR_REQUIRE()]}
                            labelName="Retailer"
                            onChange={handleStoreChange}
                            options={[
                                { value: "", label: "Select Store", disabled: true },
                                { value: "LG", label: "LG Generic" },
                                { value: "HD", label: "Home Depot Exclusive" }
                            ]}
                        />
                        <SelectInput

                            id="availability"
                            name="availability"
                            value={availability}
                            labelName="Availability"
                            onChange={handleAvailabilityChange}
                            options={[
                                { value: "", label: "Set Availability", disabled: true },
                                { value: `Available`, label: `Available` },
                                { value: `EOL`, label: `EOL` },
                                { value: `Q1 ${currentYear}`, label: `Q1 ${currentYear}` },
                                { value: `Q2 ${currentYear}`, label: `Q2 ${currentYear}` },
                                { value: `Q3 ${currentYear}`, label: `Q3 ${currentYear}` },
                                { value: `Q4 ${currentYear}`, label: `Q4 ${currentYear}` },
                                { value: `Q1 ${nextYear}`, label: `Q1 ${nextYear}` },
                                { value: `Q2 ${nextYear}`, label: `Q2 ${nextYear}` },
                                { value: `Q3 ${nextYear}`, label: `Q3 ${nextYear}` },
                                { value: `Q4 ${nextYear}`, label: `Q4 ${nextYear}` },
                            ]}
                        />
                    </PortalFormRow>


                    <PortalSectionTextBox
                        title="URL links to external website"
                        headline="Please inspect the product page once the product is added to ensure links are working properly."
                    />
                    <PortalFormRow>
                        <Input
                            id="installGuideLinkEN"
                            type="text"
                            element="input"
                            label="Installation Guide URL (English)"
                            validators={[]}
                            // validators={[VALIDATOR_URL()]}
                            // errorText="Please enter a valid URL"
                            onInput={inputHandler}
                            initialValue={formState.inputs.installGuideLinkEN.value}
                            initialIsValid={formState.inputs.installGuideLinkEN.isValid}
                        />
                        <Input
                            id="installGuideLinkFR"
                            type="text"
                            element="input"
                            label="Installation Guide URL (French)"
                            validators={[]}
                            // validators={[VALIDATOR_URL()]}
                            // errorText="Please enter a valid URL"
                            onInput={inputHandler}
                            initialValue={formState.inputs.installGuideLinkFR.value}
                            initialIsValid={formState.inputs.installGuideLinkFR.isValid}
                        />
                    </PortalFormRow>
                    <PortalFormRow>
                        <Input
                            id="specSheetLinkEN"
                            type="text"
                            element="input"
                            label="Spec Sheet Link URL (English)"
                            validators={[]}
                            // validators={[VALIDATOR_URL()]}
                            // errorText="Please enter a valid URL"
                            onInput={inputHandler}
                            initialValue={formState.inputs.specSheetLinkEN.value}
                            initialIsValid={formState.inputs.specSheetLinkEN.isValid}
                        />
                        <Input
                            id="specSheetLinkFR"
                            type="text"
                            element="input"
                            label="Spec Sheet Link URL (French)"
                            validators={[]}
                            // validators={[VALIDATOR_URL()]}
                            // errorText="Please enter a valid URL"
                            onInput={inputHandler}
                            initialValue={formState.inputs.specSheetLinkFR.value}
                            initialIsValid={formState.inputs.specSheetLinkFR.isValid}
                        />
                    </PortalFormRow>
                    <PortalFormRow>
                        <Input
                            id="ownerManualLinkEN"
                            type="text"
                            element="input"
                            label="Owners Manual Link URL (English)"
                            validators={[]}
                            // validators={[VALIDATOR_URL()]}
                            // errorText="Please enter a valid URL"
                            onInput={inputHandler}
                            initialValue={formState.inputs.ownerManualLinkEN.value}
                            initialIsValid={formState.inputs.ownerManualLinkEN.isValid}
                        />
                        <Input
                            id="ownerManualLinkFR"
                            type="text"
                            element="input"
                            label="Owners Manual Link URL (French)"
                            validators={[]}
                            // validators={[VALIDATOR_URL()]}
                            // errorText="Please enter a valid URL"
                            onInput={inputHandler}
                            initialValue={formState.inputs.ownerManualLinkFR.value}
                            initialIsValid={formState.inputs.ownerManualLinkFR.isValid}
                        />
                    </PortalFormRow>
                    <PortalSectionTextBox
                        title="Feature Videos"
                        headline="Embeded URL required. Example. www.youtube.com/embed/NjNveGgThEo, https://player.vimeo.com/video/978422792"
                    />


                    <PortalFormRow>
                        <div className={styles.specsTextAreaWrapper}>
                            <div className={styles.labelTextWrapper}>
                                <p className={styles.labelText}>
                                    Videos {`(English)`}
                                </p>
                                <p className={styles.labelInfoText}>
                                    Add one per line. Hit enter to get to the next line to add another.
                                </p>
                            </div>
                            <textarea
                                rows={10}
                                id="videosEN"
                                type="text"
                                value={formField[`videosEN`] || ''} // Use formField for input value
                                onChange={handleChange}  // Add onChange handler to update formField when input changes
                                name={`videosEN`}  // Add name property to match formField keys
                            />
                        </div>
                        <div className={styles.specsTextAreaWrapper}>
                            <div className={styles.labelTextWrapper}>
                                <p className={styles.labelText}>
                                    Videos {`(French)`}
                                </p>
                                <p className={styles.labelInfoText}>
                                    Add one per line. Hit enter to get to the next line to add another.
                                </p>
                            </div>
                            <textarea
                                rows={10}
                                id="videosFR"
                                type="text"
                                value={formField[`videosFR`] || ''} // Use formField for input value
                                onChange={handleChange}  // Add onChange handler to update formField when input changes
                                name={`videosFR`}  // Add name property to match formField keys
                            />
                        </div>
                    </PortalFormRow>


                    <PortalSectionTextBox
                        title="Technology Logos"
                        headline="Selection optional"
                    />
                    <PortalFormRow>

                        {/* NEW */}
                        <CheckboxInput
                            label="Zero Clearance"
                            value="zeroClearance"
                            id="zeroClearance"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("zeroClearance")}
                        />
                        <CheckboxInput
                            label="1 Hour Complete Wash Dry"
                            value="1HrCompleteWashDry"
                            id="1HrCompleteWashDry"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("1HrCompleteWashDry")}
                        />
                        <CheckboxInput
                            label="2 Hour Wash Dry"
                            value="2HrWashDry"
                            id="2HrWashDry"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("2HrWashDry")}
                        />
                        <CheckboxInput
                            label="4 Way Agitator"
                            value="4WayAgitator"
                            id="4WayAgitator"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("4WayAgitator")}
                        />
                        <CheckboxInput
                            label="6 Motion Direct Drive"
                            value="6MotionDirectDrive"
                            id="6MotionDirectDrive"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("6MotionDirectDrive")}
                        />
                        <CheckboxInput
                            label="Refrigeration Standard Max"
                            value="RefStandardMax"
                            id="RefStandardMax"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("RefStandardMax")}
                        />
                        <CheckboxInput
                            label="InstaView My Color"
                            value="InstaViewMyColor"
                            id="InstaViewMyColor"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("InstaViewMyColor")}
                        />

                        <CheckboxInput
                            label="Canada Reliability Badge"
                            value="CanadaReliabilityBadge"
                            id="CanadaReliabilityBadge"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("CanadaReliabilityBadge")}
                        />
                        <CheckboxInput
                            label="Certified Asthma"
                            value="CertifiedAsthma"
                            id="CertifiedAsthma"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("CertifiedAsthma")}
                        />
                        <CheckboxInput
                            label="Easy Clean"
                            value="EasyClean"
                            id="EasyClean"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("EasyClean")}
                        />
                        <CheckboxInput
                            label="LG Dynamic HeatDry"
                            value="LGDynamicHeatDry"
                            id="LGDynamicHeatDry"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("LGDynamicHeatDry")}
                        />
                        <CheckboxInput
                            label="ProBake Convection"
                            value="ProBakeConvection"
                            id="ProBakeConvection"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("ProBakeConvection")}
                        />
                        <CheckboxInput
                            label="QuadWash"
                            value="QuadWash"
                            id="QuadWash"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("QuadWash")}
                        />
                        <CheckboxInput
                            label="Slim Space Plus"
                            value="SlimSpacePlus"
                            id="SlimSpacePlus"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("SlimSpacePlus")}
                        />
                        <CheckboxInput
                            label="Smart Diagnosis"
                            value="SmartDiagnosis"
                            id="SmartDiagnosis"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("SmartDiagnosis")}
                        />
                        <CheckboxInput
                            label="Smart Inverter Compressor 10 Year Warranty"
                            value="SmartInverterCompressor10YearWarranty"
                            id="SmartInverterCompressor10YearWarranty"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("SmartInverterCompressor10YearWarranty")}
                        />
                        <CheckboxInput
                            label="TrueBalance"
                            value="TrueBalance"
                            id="TrueBalance"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("TrueBalance")}
                        />
                        <CheckboxInput
                            label="TrueSteam"
                            value="TrueSteam"
                            id="TrueSteam"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("TrueSteam")}
                        />
                        <CheckboxInput
                            label="TurboSteam"
                            value="TurboSteam"
                            id="TurboSteam"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("TurboSteam")}
                        />
                        <CheckboxInput
                            label="TurboWash3D"
                            value="TurboWash3D"
                            id="TurboWash3D"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("TurboWash3D")}
                        />
                        <CheckboxInput
                            label="WashCombo"
                            value="WashCombo"
                            id="WashCombo"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("WashCombo")}
                        />
                        {/*  */}

                        <CheckboxInput
                            label="Google"
                            value="Google"
                            id="google"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("Google")}
                        />
                        <CheckboxInput
                            label="Amazon"
                            value="Amazon"
                            id="amazon" type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("Amazon")}
                        />
                        <CheckboxInput
                            label="Asthma"
                            value="Asthma"
                            id="asthma"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("Asthma")}
                        />

                        <CheckboxInput
                            label="NSF"
                            value="NSF"
                            id="nsf"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("NSF")}
                        />
                        <CheckboxInput
                            label="Energy Star"
                            value="EnergyStar"
                            id="energyStar"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("EnergyStar")}
                        />
                        <CheckboxInput
                            label="ThinQ"
                            value="ThinQ"
                            id="thinQ"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("ThinQ")}
                        />
                        <CheckboxInput
                            label="InstaView"
                            value="InstaView"
                            id="instaview"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("InstaView")}
                        />
                        <CheckboxInput
                            label="WashTower"
                            value="WashTower"
                            id="washtower"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("WashTower")}
                        />
                        <CheckboxInput
                            label="Craft Ice"
                            value="CraftIce"
                            id="craftIce"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("CraftIce")}
                        />
                        <CheckboxInput
                            label="Door-in-Door Craft Ice"
                            value="DiDCraftIce"
                            id="diDCraftIce"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("DiDCraftIce")}
                        />
                        <CheckboxInput
                            label="Counter-Depth MAX"
                            value="CounterDepthMax"
                            id="counterDepthMax"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("CounterDepthMax")}
                        />
                        <CheckboxInput
                            label="Innit"
                            value="Innit"
                            id="innit"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("Innit")}
                        />
                        <CheckboxInput
                            label="SideChef"
                            value="SideChef"
                            id="sideChef"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("SideChef")}
                        />
                        <CheckboxInput
                            label="QuadWash Pro"
                            value="QuadWashPro"
                            id="quadWashPro"
                            type="checkbox"
                            onChange={handleLogoChange}
                            checked={selectedLogos.includes("QuadWashPro")}
                        />
                    </PortalFormRow>
                    <PortalSectionTextBox
                        title="Model Colours"
                        headline="Minimum 1 selection"
                    />
                    <PortalFormRow>

                        {/* NEW */}
                        <CheckboxInput
                            label="Platinum Silver"
                            value="Platinum Silver"
                            id="platinum-silver"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Platinum Silver") : false} />
                        <CheckboxInput
                            label="Glass Beige"
                            value="Glass Beige"
                            id="glass-beige"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Glass Beige") : false} />

                        <CheckboxInput
                            label="Nature Green"
                            value="Nature Green"
                            id="nature-green"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Nature Green") : false} />

                        <CheckboxInput
                            label="Essence White"
                            value="Essence White"
                            id="essence-white"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Essence White") : false} />

                        <CheckboxInput
                            label="Candy Apple Red"
                            value="Candy Apple Red"
                            id="candy-apple-red"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Candy Apple Red") : false} />
                        <CheckboxInput
                            label="Noble Steel"
                            value="Noble Steel"
                            id="noble-steel"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Noble Steel") : false} />
                        <CheckboxInput
                            label="Monochrome grey"
                            value="Monochrome grey"
                            id="monochrome-grey"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Monochrome grey") : false} />

                        <CheckboxInput
                            label="Mirror"
                            value="Mirror"
                            id="Mirror"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Mirror") : false} />

                        {/* */}


                        <CheckboxInput
                            label="White"
                            value="White"
                            id="white"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("White") : false}
                        />
                        <CheckboxInput
                            label="Smooth White"
                            value="Smooth White"
                            id="smooth-white"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Smooth White") : false}
                        />
                        <CheckboxInput
                            label="Smooth Black"
                            value="Smooth Black"
                            id="smooth-black"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Smooth Black") : false}
                        />
                        <CheckboxInput
                            label="Black Steel"
                            value="Black Steel"
                            id="black-steel"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Black Steel") : false}
                        />
                        <CheckboxInput
                            label="Graphite Steel"
                            value="Graphite Steel"
                            id="graphite-steel"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Graphite Steel") : false}
                        />
                        <CheckboxInput
                            label="Stainless Steel"
                            value="Stainless Steel"
                            id="stainless-steel"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Stainless Steel") : false}
                        />
                        <CheckboxInput
                            label="Black Stainless Steel"
                            value="Black Stainless Steel"
                            id="black-stainless-steel"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Black Stainless Steel") : false}
                        />
                        <CheckboxInput
                            label="Smudge Resistant Stainless Steel"
                            value="Smudge Resistant Stainless Steel"
                            id="smudge-resistant-stainless-steel"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Smudge Resistant Stainless Steel") : false}
                        />
                        <CheckboxInput
                            label="Smudge Resistant Matte Black"
                            value="Smudge Resistant Matte Black"
                            id="smudge-resistant-matte-black"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Smudge Resistant Matte Black") : false}
                        />
                        <CheckboxInput
                            label="Smudge Resistant Black Stainless Steel"
                            value="Smudge Resistant Black Stainless Steel"
                            id="smudge-resistant-black-stainless-steel"
                            type="checkbox"
                            onChange={handleColourChange}
                            checked={selectedColours ? selectedColours.includes("Smudge Resistant Black Stainless Steel") : false} />
                    </PortalFormRow>
                    <PortalSectionTextBox
                        title="Image File Upload"
                        headline="Product Image Required"
                    />
                    <PortalFormImageUploadSection
                        label="Upload Product Image"
                        note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp (recommended). Image should have transparent background."
                    >
                        <div className={styles.fimgwrap}>
                            {previewUrl && (
                                // <div className={styles.imagePreviewBox}>
                                <div className={styles.imagePreviewBox}>
                                    <img src={selectedFile ? previewUrl : `${process.env.REACT_APP_AWS_URL}/` + loadedProduct.image} alt="Preview" />
                                </div>
                            )}
                            <div>
                                <input
                                    ref={filePickerRef}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept=".jpg, .png, .jpeg, .webp"
                                    onChange={handleFileChange} />
                                <span onClick={pickImageHandler}>
                                    <TheButton styleName='black' type="button" animated="true">
                                        {previewUrl ? 'Change Image' : "+ Add"}
                                    </TheButton>
                                </span>
                            </div>
                        </div>
                        {/* </PortalFormRow> */}

                    </PortalFormImageUploadSection>

                    {/* {
                        loadedProduct.qrCodeSpecSheetEN && loadedProduct.qrCodeSpecSheetEN !== null
                            ?
                            <PortalFormImageUploadSection
                                label={loadedProduct.qrCodeSpecSheetEN &&
                                    loadedProduct.qrCodeSpecSheetEN.length !== 0 ? 'Update English QR Code Image' : 'Upload English QR Code Image'
                                }
                                note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp. Image should have transparent background."
                            >
                                <div className={styles.fimgwrap}>
                                    {

                                        selectedQrCodeSpecSheetENFile !== null || loadedProduct.qrCodeSpecSheetEN !== null ?
                                            (
                                                <div className={styles.imagePreviewBox}>
                                                    <img src={selectedQrCodeSpecSheetENFile ? previewQRCodeSpecSheetENUrl : `${process.env.REACT_APP_AWS_URL}/` + loadedProduct.qrCodeSpecSheetEN} alt={loadedProduct.qrCodeSpecSheetEN} />
                                                </div>
                                            ) : null
                                    }

                                    <div>
                                        <input
                                            ref={filePickerRefQrCodeSpecSheetEN}
                                            style={{ display: 'none' }}
                                            type="file"
                                            accept=".jpg, .png, .jpeg, .webp"

                                            onChange={handleFileChangeQrCodeSpecSheetEN} />
                                        <span
                                            onClick={pickQrCodeSpecSheetENImageHandler}
                                        >

                                            <TheButton
                                                styleName='black'
                                                type="button"

                                            >
                                                {loadedProduct.qrCodeSpecSheetEN
                                                    && loadedProduct.qrCodeSpecSheetEN.length !== 0
                                                    || selectedQrCodeSpecSheetENFile
                                                    ? 'Change QR Code Image'
                                                    :
                                                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                                                        Upload QR Code
                                                    </span>
                                                }
                                            </TheButton>

                                        </span>
                                        <span onClick={clearQrCodeSpecSheetENFileHandler}>Remove</span>
                                    </div>

                                </div>

                            </PortalFormImageUploadSection>
                            : null
                    } */}

                    {
                        loadedProduct.qrCodeSpecSheetEN !== null &&
                        <PortalFormImageUploadSection
                            label={loadedProduct.qrCodeSpecSheetEN &&
                                loadedProduct.qrCodeSpecSheetEN.length !== 0 ? 'Update English QR Code Image' : 'Upload English QR Code Image'
                            }
                            note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp. Image should have transparent background."
                        >
                            <div className={styles.fimgwrap}>
                                {

                                    (selectedQrCodeSpecSheetENFile !== null || previewQRCodeSpecSheetENUrl !== null || (loadedProduct.qrCodeSpecSheetEN && loadedProduct.qrCodeSpecSheetEN !== null)) && (
                                        <div className={styles.imagePreviewBox}>
                                            <img
                                                src={
                                                    selectedQrCodeSpecSheetENFile
                                                        ? previewQRCodeSpecSheetENUrl
                                                        : loadedProduct.qrCodeSpecSheetEN
                                                            ? `${process.env.REACT_APP_AWS_URL}/` + loadedProduct.qrCodeSpecSheetEN
                                                            // ? selectedQrCodeSpecSheetENFile
                                                            : null
                                                }
                                                alt="QR Code Preview"
                                            />
                                        </div>
                                    )

                                }

                                <div>
                                    <input
                                        ref={filePickerRefQrCodeSpecSheetEN}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept=".jpg, .png, .jpeg, .webp"

                                        onChange={handleFileChangeQrCodeSpecSheetEN} />
                                    <span
                                        onClick={pickQrCodeSpecSheetENImageHandler}
                                    >

                                        <TheButton
                                            styleName='black'
                                            type="button"

                                        >
                                            {loadedProduct.qrCodeSpecSheetEN
                                                && loadedProduct.qrCodeSpecSheetEN.length !== 0
                                                || selectedQrCodeSpecSheetENFile
                                                ? 'Change QR Code Image'
                                                :
                                                <span style={{ display: 'flex', flexDirection: 'row' }}>
                                                    Upload QR Code
                                                </span>
                                            }
                                        </TheButton>

                                    </span>

                                </div>

                                <span onClick={clearQrCodeSpecSheetENFileHandler}>
                                    <TheButton styleName="white">
                                        Remove
                                    </TheButton>

                                </span>
                            </div>

                        </PortalFormImageUploadSection>
                    }

                    {
                        loadedProduct.qrCodeSpecSheetFR !== null &&
                        <PortalFormImageUploadSection
                            label={loadedProduct.qrCodeSpecSheetFR &&
                                loadedProduct.qrCodeSpecSheetFR.length !== 0 ? 'Update French QR Code Image' : 'Upload French QR Code Image'
                            }
                            note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp. Image should have transparent background."
                        >
                            <div className={styles.fimgwrap}>
                                {

                                    (selectedQrCodeSpecSheetFRFile !== null || previewQRCodeSpecSheetFRUrl !== null || (loadedProduct.qrCodeSpecSheetFR && loadedProduct.qrCodeSpecSheetFR !== null)) && (
                                        <div className={styles.imagePreviewBox}>
                                            <img
                                                src={
                                                    selectedQrCodeSpecSheetFRFile
                                                        ? previewQRCodeSpecSheetFRUrl
                                                        : loadedProduct.qrCodeSpecSheetFR
                                                            ? `${process.env.REACT_APP_AWS_URL}/` + loadedProduct.qrCodeSpecSheetFR
                                                            // ? selectedQrCodeSpecSheetENFile
                                                            : null
                                                }
                                                alt="QR Code Preview"
                                            />
                                        </div>
                                    )

                                }

                                <div>
                                    <input
                                        ref={filePickerRefQrCodeSpecSheetFR}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept=".jpg, .png, .jpeg, .webp"

                                        onChange={handleFileChangeQrCodeSpecSheetFR} />
                                    <span
                                        onClick={pickQrCodeSpecSheetFRImageHandler}
                                    >

                                        <TheButton
                                            styleName='black'
                                            type="button"

                                        >
                                            {loadedProduct.qrCodeSpecSheetFR
                                                && loadedProduct.qrCodeSpecSheetFR.length !== 0
                                                || selectedQrCodeSpecSheetFRFile
                                                ? 'Change QR Code Image'
                                                :
                                                <span style={{ display: 'flex', flexDirection: 'row' }}>
                                                    Upload QR Code
                                                </span>
                                            }
                                        </TheButton>

                                    </span>

                                </div>
                                <span onClick={clearQrCodeSpecSheetFRFileHandler}>
                                    <TheButton styleName="white">
                                        Remove
                                    </TheButton>
                                </span>
                            </div>

                        </PortalFormImageUploadSection>
                    }

                    {/* <PortalFormImageUploadSection
                        label={
                            loadedProduct.qrCodeSpecSheetFR &&
                                loadedProduct.qrCodeSpecSheetFR.length !== 0 ? 'Update French QR Code Image' : 'Upload French QR Code Image'
                        }
                        note="File Size Max: 5MB. Image formats: jpg, jpeg, png, webp. Image should have transparent background."
                    >
                        <div className={styles.fimgwrap}>
                            {
                                selectedQrCodeSpecSheetFRFile || loadedProduct.qrCodeSpecSheetFR !== null ?

                                    (
                                        <div className={styles.imagePreviewBox}>
                                            <img src={selectedQrCodeSpecSheetFRFile ? previewQRCodeSpecSheetFRUrl : `${process.env.REACT_APP_AWS_URL}/` + loadedProduct.qrCodeSpecSheetFR} alt={loadedProduct.qrCodeSpecSheetFR} />
                                        </div>
                                    ) : null
                            }
                            <div>
                                <input
                                    ref={filePickerRefQrCodeSpecSheetFR}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept=".jpg, .png, .jpeg, .webp"

                                    onChange={handleFileChangeQrCodeSpecSheetFR} />
                                <span onClick={pickQrCodeSpecSheetFRImageHandler}>
                                    <TheButton
                                        styleName='black'
                                        type="button"

                                    >
                                        {
                                            loadedProduct.qrCodeSpecSheetFR &&
                                                loadedProduct.qrCodeSpecSheetFR.length !== 0 || selectedQrCodeSpecSheetFRFile ? 'Change QR Code Image' : (<span style={{ display: 'flex', flexDirection: 'row' }}>

                                                    Upload QR Code Image
                                                </span>)}

                                    </TheButton>
                                </span>
                                <span onClick={clearQrCodeSpecSheetFRFileHandler}>Remove</span>
                            </div>
                        </div>
                    </PortalFormImageUploadSection> */}
                    <ResourceFormSection initialSections={initialSections} onSectionsChange={setSections} />
                    <PortalFormRow>
                        <div>
                            <TheButton
                                type='submit'
                                styleName="red"
                                animated="true"
                            >
                                Update Product
                            </TheButton>
                        </div>
                    </PortalFormRow>
                </PortalForm >
            }


        </>
    );
};

export default UpdateProductPortalFormPage;